// React
// import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

// Services
import { toastOptions } from "./service/SnackbarService";
import { containerStyle } from "./service/SnackbarService";
// Thrid Party
import { Toaster } from "react-hot-toast";
import Router from "./routes/Router";
// Store
import { AuthContextProvider } from "./store/auth-context";
//Sentry
import * as Sentry from "@sentry/react";

import AppConstants  from "./configs/AppConstants";

import { GoogleOAuthProvider } from "@react-oauth/google";


// Sentry.init({
//   dsn: window.location.hostname === "localhost" ? AppConstants.SENTRY_DSN_URL_DEV :  AppConstants.SENTRY_DSN_URL_PROD,
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [AppConstants.HOST, AppConstants.HOST_REGEX],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // Session Replay
//   //replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysSessionSampleRate: 10,
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

function App() {
  return (
    <div className="App">
          <GoogleOAuthProvider
        clientId={
          process.env.REACT_APP_GOOGLE_CLIENT_ID ||
          AppConstants.CLIENT_ID
        }
        >
      <AuthContextProvider>
        <Toaster key="toaster" {...{ toastOptions }} {...{ containerStyle }} />
        <Router />

      </AuthContextProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
