import { GET } from "./CRUDService";

const DownloadFile = async (downloadZipUrl, transaction_id, bulk_download, showMessageModal) => {
  const urlWithParams = `${downloadZipUrl}${transaction_id}`;

  try {
    const response = await GET(urlWithParams);

    if (!response.ok) {
      console.error("Error fetching response:", response.statusText);
      showMessageModal(false, "Download Failed", "Failed to fetch data from the server.");
      return;
    }

    // Check if it is a zip download
    if (bulk_download) {
      await handleZipDownloadResponse(response, transaction_id, showMessageModal);
    } else {
      await handleCredentialDownloadResponse(response, transaction_id, showMessageModal);
    }
  } catch (error) {
    console.error("Error downloading file:", error);
    showMessageModal(false, "Download Failed", "An error occurred while processing your request.");
  }
};

const handleZipDownloadResponse = async (response, transaction_id, showMessageModal) => {
  try {
    const responseData = await response.json();
    const isSuccess = response.ok;

    if (responseData.message) {
      showMessageModal(isSuccess, responseData.status, responseData.message);
    } else if (responseData.download_url) {
      const filename = `${transaction_id}.zip`; // Set transaction_id as filename
      await downloadBlob(responseData.download_url, filename);
    } else if (responseData.error) {
      showMessageModal(false, "Download Failed", responseData.error);
    } else {
      console.error("Unexpected response format:", responseData);
      showMessageModal(false, "Download Failed", "Unexpected response format.");
    }
  } catch (error) {
    console.error("Error parsing ZIP response:", error);
    showMessageModal(false, "Download Failed", "Failed to process the ZIP response.");
  }
};

const handleCredentialDownloadResponse = async (response, transaction_id, showMessageModal) => {
  if (response.ok) {
    const blob = await response.blob();
    let filename = `${transaction_id}`;

    const contentDisposition = response.headers.get("Content-Disposition");
    console.log("contentDisposition : ", contentDisposition);
    const contentType = response.headers.get("Content-Type");
    console.log("contentType : ", contentType);

    // Extract filename from Content-Disposition header if available
    if (contentDisposition && contentDisposition.includes("filename=")) {
      const match = contentDisposition.match(/filename="(.+?)"/);
      console.log("match>>>>>>", match);
      if (match && match[1]) {
        filename = match[1];
      }
    }
    const url = window.URL.createObjectURL(blob);
    await downloadBlob(url, filename);
  } else {
    console.error("Error fetching the file:", response.statusText);
    showMessageModal(false, "Download Failed", "Failed to download the file.");
  }
};

const downloadBlob = (url, filename) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export default DownloadFile;
