import * as Yup from 'yup';

export const state = {
    first_name: "",
    last_name: "",
    gender:"",
    dob:"",
    country_code:"+91",
    phone_no:"",
    email: "",
    org_code: null,
    password: "",
    confirm_password: ""
};

export const schema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    gender: Yup.string().notRequired(),
    dob: Yup.date().notRequired(),
    country_code: Yup.string().notRequired(),
    phone_no: Yup.string().notRequired(),
    email: Yup.string().required("Email is required").email("Provide a valid email address"),
    password: Yup.string().required("Password is required")
        .matches(/^(?=.*[A-Z])/, 'Password must contain at least one uppercase letter.')
        .matches(/^(?=.*[a-z])/, 'Password must contain at least one lowercase letter.')
        .matches(/^(?=.*\d)/, 'Password must contain at least one number.')
        .matches(/^(?=.*[~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/])/, 'Password must contain at least one special character.')
        .min(8, 'Password must contain at least 8 characters.'),
    confirm_password: Yup.string().required("Re-enter password is required").oneOf([Yup.ref("password")], "Passwords do not match"),
    org_code: Yup.string().notRequired()
});
