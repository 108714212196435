import React from "react";

export const getPieChartOptions = () => {
  return {
    labels: ["Issued", "Revoked", "Failed", "Expired"],
    colors: ["#808080"],
    chart: {
      width: "50px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: ["#FFFFFF"],
      },
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    fill: {
      colors: ["#3366CC", "#DC3912", "#FF9900", "#b9b9b9"],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      style: {
        fontSize: "12px",
        fontFamily: undefined,
        backgroundColor: "#000000",
      },
      y: {
        formatter: function (value) {
          return value + "%";
        },
      },
    },
  };
};

export const getPieChartSeries = (credentialDistribution) => {
  return [
    credentialDistribution?.issued || 0,
    credentialDistribution?.revoked || 0,
    credentialDistribution?.failed || 0,
    credentialDistribution?.expired || 0,
  ];
};
