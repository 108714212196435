import React, { useContext, useEffect , useState } from "react";
import { NavLink, useParams } from 'react-router-dom';
import EntityConstants from "../../configs/EntityConstants";
import { GET } from "../../service/CRUDService";


const ActivateAccount = () => {
  const [message,setMessage] = useState("");

  const {uuid,token} = useParams();

  // const activate = async (uuid,token) => {
  //   const response = await GET(`${EntityConstants.ACTIVATE_ACCOUNT}/${uuid}/${token}/`);
  //   const data = await response.json();
  //     if (response.ok) {
  //       setMessage(data.message)
  //     }else{
  //       console.log("error");
  //     }
  // }
  const activate = async (uuid, token) => {
    try {
        const response = await GET(`${EntityConstants.ACTIVATE_ACCOUNT}/${uuid}/${token}/`);
        const data = await response.json();

        if (response.ok) {
            setMessage(data.message);
        } else {
            console.log("Error:", data);
        }
    } catch (error) {
        // Handle unexpected errors
        console.error("Activation failed:", error);
        // You may want to display an error message to the user or log the error
    }
};

  useEffect(() => {
        if (uuid && token) {
        //GET Validation/Activation
        activate(uuid,token);
        }
}, [uuid,token]);
  return <div className="h-screen w-full relative">
    {/* {message && 
    <div className="flex flex-col gap-6">
      <div className="flex flex-col items-center w-3/5 sm:w-96 bg-sky-200 rounded-md p-2  shadow-md my-3">
      <p>{message}</p>
      </div>
      <p>Login or go to&nbsp;<NavLink to={'/'} className="text-cfSecondary bg-cfPrimary p-2 rounded-md hover:no-underline hover:bg-cfPrimaryLight">Home</NavLink></p>
      </div>
    } */}
    {message && <section className="bg-cfSecondary w-full absolute left-0 top-[30%]">
    <div className="container flex flex-col items-center px-4 py-12 mx-auto text-center">
        {/* <h2 className="max-w-2xl mx-auto text-2xl font-semibold tracking-tight text-gray-800 xl:text-3xl dark:text-cfSecondary">
            Bring your Business to the <span className="text-blue-500">next level.</span>
        </h2> */}

        <p className="max-w-4xl mt-6 text-center text-gray-500">
        {message}
        </p>

        <div className="flex justify-center items-center mt-6">
        Login or go to&nbsp;<NavLink to={'/'} className="inline-flex w-fit hover:no-underline items-center justify-center px-6 py-2 text-cfSecondary duration-300 bg-blue-600 rounded-lg hover:bg-blue-500 focus:ring focus:ring-none ">Home</NavLink>
        </div>
    </div>
</section>}
    </div>;
};

export default ActivateAccount;
