import React, { useEffect, useContext, useState, useRef } from "react";
import CredentialTemplateCard from "../../components/Cards/CredentialTemplateCard/CredentialTemplateCard";
import EntityConstants from "../../configs/EntityConstants";
import { GET } from "../../service/CRUDService";
import Tag from "../../common/Tag/Tag";
import AuthContext from "../../store/auth-context";
import { useNavigate } from "react-router-dom";
import CalculateCardsPerRow from "../../service/CalculateCardsPerRow";

function Discovery() {
  const authContext = useContext(AuthContext);

  const [cardWidth, setCardWidth] = useState("19.125rem");
  const [gap, setGap] = useState("1.5rem");
  const containerRef = useRef(null);
  const [cardsPerRow, setCardsPerRow] = useState(0);

  const [isSearching, setIsSearching] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [advanceSearchTags, setAdvanceSearchTags] = useState([]);
  const [respContentList, setRespContentList] = useState([]);
  const [respCategoryList, setRespCategoryList] = useState([]);
  const [contextList, setContextList] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState([]);
  const [tagsList, setTagList] = useState([]);
  const [count, setCount] = useState(0);
  const [countSearch, setCountSearch] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setlimit] = useState(8);
  const [resetTags, setResetTags] = useState(false);
  const [showAllCategories, setShowAllCategories] = useState(false);

  const navigate = useNavigate();

  const [isLodingCredTemplates, setIsLoadingCredTemplates] = useState(false);

  const GET_TEMPLATE_LIST = async (limit, offset, cardsPerRow) => {
    setIsLoadingCredTemplates(true);
    await GET(
      `${EntityConstants.DISCOVERY_TEMPLATE}limit=${limit}&offset=${offset}&cred_per_category=${cardsPerRow}`
    )
      .then((response) => response.json())
      .then((data) => {
        setCount(data.count);
        setOffset(offset);
        setRespContentList([...respContentList, ...data.results]);
        setRespCategoryList([...respCategoryList, ...data.results]);
      })
      .then(() => setIsLoadingCredTemplates(false))
      .catch((error) => {
        console.error(error);
      })
      .then(() => setIsLoadingCredTemplates(false));
  };

  const GET_DEFAULT_CATEGORY = async (cardsPerRow) => {
    await GET(
      `${EntityConstants.DISCOVERY_DEFAULT_CATEGORY}?limit=${cardsPerRow}&offset=0`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setDefaultCategory(data.results);
      });
  };

  useEffect(() => {
    const updateCardsPerRow = () => {
      if (containerRef.current) {
        const calculatedCardsPerRow = CalculateCardsPerRow(
          containerRef,
          cardWidth,
          gap
        );
        if (calculatedCardsPerRow < 5) {
          setCardsPerRow(4);
        } else {
          setCardsPerRow(calculatedCardsPerRow);
        }
      }
    };

    updateCardsPerRow();
    // window.addEventListener('resize', updateCardsPerRow);
    // return () => window.removeEventListener('resize', updateCardsPerRow);
  }, [cardWidth, gap]);

  useEffect(() => {
    if (respContentList) {
      setContextList([...respContentList]);
    }
  }, [respContentList]);

  useEffect(() => {
    if (respCategoryList) {
      setDefaultCategory([...respCategoryList]);
    }
  }, [respCategoryList]);

  useEffect(() => {
    if (
      authContext.hasDiscoveryReachedBottom &&
      !isSearched &&
      contextList.length < count &&
      cardsPerRow > 0
    ) {
      // The user has reached the bottom of the page
      // You can load more content or trigger some action here
      GET_TEMPLATE_LIST(limit, offset + limit, cardsPerRow);
    }
  }, [authContext.hasDiscoveryReachedBottom]);

  useEffect(() => {
    if (cardsPerRow > 0) {
      GET_DEFAULT_CATEGORY(cardsPerRow);
      GET_TEMPLATE_LIST(limit, offset, cardsPerRow);
    }
    sessionStorage.setItem("workflow_id", null);
  }, [cardsPerRow]);

  const curretSearch = (e) => {
    setIsSearching(true);

    const searchTerm = e.target.value ? e.target.value.trim() : "";

    if (searchTerm.length > 2) {
      GET(`${EntityConstants.DISCOVERY_SEARCH}${searchTerm}`)
        .then((response) => response.json())
        .then((data) => {
          const credentials = data.credentials || [];
          const categories = data.categories || [];
          setCountSearch(categories?.length || 0);
          setContextList(credentials);
          setDefaultCategory(categories);
        })
        .catch((error) => {
          console.log("Error is : ", error);
          setContextList([]);
          setCountSearch(0);
        })
        .finally(() => {
          setIsSearched(true);
          setIsSearching(false);
        });
    } else {
      setContextList([...respContentList]);
      setDefaultCategory([...respCategoryList]);
      setIsSearched(false);
      setIsSearching(false);
      setCountSearch(0);
    }
  };

  return (
    <div>
      <div className="h-full ">
        <div className="bxm font-CfPrimaryFont bg-cfSecondary min-h-screen">
          <div className="flex flex-col items-center w-full mb-4 ">
            <div
              className={`w-full bg-cfSearchContainer flex justify-center py-[0.8rem] px-4`}
            >
              <div
                className={`flex flex-col sm:flex-row items-start sm:items-center justify-between w-full`}
              >
                <p className="text-cfHeadingText font-semibold self-center md:self-start my-auto">
                  Choose a template
                </p>
                {/* Search */}
                <form
                  onSubmit={(e) => e.preventDefault()}
                  className=" bg-cfSecondary p-2 rounded-lg w-full sm:w-[25rem] self-center ml-[0.630rem] md:self-end md:m-0"
                >
                  <input
                    type="text"
                    name="search"
                    id="search"
                    onChange={(e) => curretSearch(e)}
                    placeholder="Search for a certificate or category"
                    className="outline-none border-none p-0 placeholder:text-sm w-full"
                    style={{ fontSize: "0.875rem", lineHeight: "1.25rem" }}
                  />
                </form>
              </div>
            </div>

            <div className="flex flex-col gap-y-4 p-4 w-full">
              <div className="flex flex-col sm:flex-row justify-between w-full">
                <div className="w-full">
                  <div className="flex justify-between mb-[1.063rem] flex-wrap">
                    <div className="flex items-center gap-2">
                      <p className="text-cfHeadingText font-semibold text-lg">
                        Categories
                      </p>
                      {!isSearched && !isSearching && (
                        <p className="text-cfTotalCertificates">({count})</p>
                      )}
                      {isSearched && (
                        <p className="text-cfTotalCertificates">
                          ({countSearch})
                        </p>
                      )}
                    </div>
                    {!showAllCategories && (
                      <div
                        onClick={() => {
                          setShowAllCategories(true);
                          navigate("/categories");
                        }}
                        className="font-cfPrimaryFont text-sm font-semibold text-cfViewAllTagText cursor-pointer"
                      >
                        <p>View all categories</p>
                      </div>
                    )}
                  </div>
                  <div className="flex items-center w-full gap-6 flex-wrap">
                    {defaultCategory.length > 0 &&
                      (showAllCategories
                        ? defaultCategory
                        : defaultCategory.slice(0, 4)
                      ).map(
                        ({
                          category_name,
                          category_description,
                          _1,
                          thumbnail_images,
                          _2,
                          _3,
                          _4,
                          category_id,
                        }) => (
                          <div
                            key={category_id}
                            className={`w-[19.125rem] h-[5.563rem] flex rounded-lg overflow-hidden border-[1.5px] border-cfCardBorder shadow-sm shadow-cfCategoryCardShadow/50 ${
                              category_id
                                ? "cursor-pointer"
                                : "cursor-not-allowed"
                            }`}
                            onClick={() =>
                              category_id &&
                              navigate(
                                `/certificates/${category_name.replace(
                                  / /g,
                                  "+"
                                )}/${category_id}`
                              )
                            }
                          >
                            <div
                              className={`min-w-[5.625rem] w-[5.625rem] px-3 py-3 bg-cfSearchContainer`}
                            >
                              <img
                                className={`w-full h-full object-cover object-top`}
                                src={
                                  thumbnail_images?.length > 0 &&
                                  thumbnail_images[0]
                                }
                                onError={(e) => {
                                  e.target.src =
                                    thumbnail_images?.length > 0 &&
                                    thumbnail_images[0];
                                }}
                                alt="certificate"
                              />
                            </div>
                            <div className="flex flex-col px-[0.438rem] my-auto">
                              <p className="text-cfCategoryCardHeadingText font-semibold text-sm line-clamp-1">
                                {category_name}
                              </p>
                              <p className={`text-xs line-clamp-2`}>
                                {category_description}
                              </p>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
              <div ref={containerRef} className="w-full">
                {isSearched && (
                  <div className="text-cfHeadingText font-semibold text-lg ">
                    <p>Searched certificates</p>
                  </div>
                )}
                {isSearching || isSearched ? (
                  <div className="flex items-center gap-6 flex-wrap w-full py-[0.6rem]">
                    {contextList.length > 0 &&
                      contextList.map(
                        (
                          {
                            name,
                            description,
                            credential_template_id,
                            tags,
                            thumbnail_svg_url,
                          },
                          index
                        ) => (
                          <CredentialTemplateCard
                            key={index}
                            name={name}
                            description={description}
                            credential_template_id={credential_template_id}
                            tagsList={tags}
                            thumbnail_image={thumbnail_svg_url}
                            navigate_url={`/certificate/0/${credential_template_id}`}
                          />
                        )
                      )}
                    {isSearched && contextList.length === 0 && (
                      <p className="text-grey-50 font-semibold text-lg mt-9">
                        No results found
                      </p>
                    )}
                  </div>
                ) : (
                  contextList.length !== 0 &&
                  contextList.map(
                    ({
                      category_id,
                      category_name,
                      credentials_count,
                      credentials,
                    }) =>
                      credentials_count > 0 && (
                        <div
                          className="flex flex-col gap-3 pb-6 last:pb-0"
                          key={category_name}
                        >
                          <div className="flex flex-col sm:grid grid-cols-auto-fit-minmax justify-between w-full gap-y-3">
                            <div className="flex items-center gap-2">
                              <p className="text-cfHeadingText font-semibold text-lg">
                                {category_name}
                              </p>
                              <p className="text-cfTotalCertificates">{`(${credentials_count})`}</p>
                            </div>
                            {!(credentials.length >= credentials_count) && (
                              <div
                                className={`font-cfPrimaryFont text-sm font-semibold text-cfViewAllTagText text-right cursor-pointer`}
                                onClick={() =>
                                  category_id &&
                                  navigate(
                                    `/certificates/${category_name.replace(
                                      / /g,
                                      "+"
                                    )}/${category_id}`
                                  )
                                }
                              >
                                <p>View all</p>
                              </div>
                            )}
                          </div>

                          <div className="flex items-center gap-6 flex-wrap w-full">
                            {Array.isArray(credentials) &&
                              credentials.map(
                                (
                                  {
                                    name,
                                    description,
                                    credential_template_id,
                                    tags,
                                    thumbnail_svg_url,
                                  },
                                  index
                                ) => {
                                  return (
                                    <>
                                      <CredentialTemplateCard
                                        key={index}
                                        name={name}
                                        description={description}
                                        credential_template_id={
                                          credential_template_id
                                        }
                                        tagsList={tags}
                                        thumbnail_image={thumbnail_svg_url}
                                        navigate_url={`/certificate/0/${credential_template_id}`}
                                      />
                                    </>
                                  );
                                }
                              )}
                          </div>
                        </div>
                      )
                  )
                )}
              </div>
              <div>{isLodingCredTemplates && <CredentialTemplateCard />}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Discovery;
