/**
 * GenerateCertificate Component
 *
 * Version: 2.0.0
 *
 * Description:
 * This component generates a certificate SVG based on form data provided.
 * It processes the SVG template by replacing placeholders with actual values
 * from the form data. Additionally, it handles image replacements for specific
 * data keys and combines recipientFirstName and recipientLastName to form the
 * recipientName for the certificate.
 *
 * How It Works:
 * 1. Parses the SVG template using DOMParser.
 * 2. Processes form data to identify and replace placeholders.
 * 3. Handles image replacements for specific data keys.
 * 4. Combines recipientFirstName and recipientLastName to form recipientName.
 * 5. Returns the modified SVG as a string.
 *
 * Date: April 18, 2024
 */

import { imgRegex, dateTimeRegex } from "../configs/App.Regex";

const formDateTime = (value) => {
  const date = new Date(value);
  if (isNaN(date)) {
    return value;
  }

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const GenerateCertificate = (currentSVG, formData) => {
  // Check for invalid inputs
  if (
    !currentSVG ||
    !formData ||
    typeof formData !== "object" ||
    Object.keys(formData).length === 0
  ) {
    return currentSVG;
  }

  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(currentSVG, "image/svg+xml");
  const serializer = new XMLSerializer();

  let imageReplacements = [];
  let textReplacements = [];
  let placeholders = [];

  // Mapping function to handle recipientFirstName and recipientLastName
  const mapFormData = (formData) => {
    let updatedFormData = { ...formData };

    if (formData.recipientFirstName || formData.recipientLastName) {
      updatedFormData.recipientName = `${
        formData.recipientFirstName ? formData.recipientFirstName : ""
      } ${formData.recipientLastName ? formData.recipientLastName : ""}`.trim();
    }

    // Date time fields
    Object.entries(updatedFormData).forEach(([key, value]) => {
      if (value !== null && value !== undefined && dateTimeRegex.test(value)) {
        updatedFormData[key] = formDateTime(value);
      }
    });

    return updatedFormData;
  };

  const mappedFormData = mapFormData(formData);

  // Process form data
  Object.entries(mappedFormData).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      const stringValue = String(value); // Convert value to string

      if (imgRegex.test(stringValue)) {
        imageReplacements.push({ key, value: stringValue });
      } else {
        const textElements = svgDoc.querySelectorAll(`[data-name="${key}"]`);

        if (textElements.length > 0) {
          textReplacements.push({ elements: textElements, value: stringValue });
        } else {
          placeholders.push(`{{${key}}}`);
        }
      }
    }
  });

  // Apply image replacements
  imageReplacements.forEach(({ key, value }) => {
    if (!value) return;
    const imageElements = svgDoc.querySelectorAll(`[data-name="${key}.png"]`);
    imageElements.forEach((element) =>
      element.setAttribute("xlink:href", value)
    );
  });

  // Apply text replacements
  textReplacements.forEach(({ elements, value }) => {
    if (!value) return;
    elements.forEach((element) => (element.textContent = value));
  });

  // Serialize svgDoc to update modifiedSVG
  let modifiedSVG = serializer.serializeToString(svgDoc);

  // Apply fallback replacements using single regular expression
  const fallbackRegex = new RegExp(placeholders.join("|"), "g");
  modifiedSVG = modifiedSVG.replace(fallbackRegex, (match) => {
    const found = mappedFormData[match.slice(2, -2)];
    return found !== undefined ? found : match;
  });

  return modifiedSVG;
};

export default GenerateCertificate;
