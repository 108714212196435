import { useContext, useEffect, useRef, useState } from "react";
import ArrowLeftIcon from "../../resources/icons/arrow-narrow-left.svg";
import { useNavigate } from "react-router-dom";
import CategoryCard from "../../components/Cards/CategoryCard/CategoryCard";
import { GET } from "../../service/CRUDService";
import EntityConstants from "../../configs/EntityConstants";
import AuthContext from "../../store/auth-context";
import CalculateCardsPerRow from "../../service/CalculateCardsPerRow";

const Categories = () => {
  const authContext = useContext(AuthContext);

  const [limit, setLimit] = useState(0);
  const [offset, setOffset] = useState(0);
  const [categoriesList, setCategoriesList] = useState([]);
  const [fullCategoriesList, setFullCategoriesList] = useState([]);
  const [respCategoriesList, setRespCategoriesList] = useState([]);
  const [count, setCount] = useState(0);

  const [cardWidth, setCardWidth] = useState("19.125rem");
  const [gap, setGap] = useState("0.75rem");
  const containerRef = useRef(null);

  const [isSearching, setIsSearching] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);

  const navigate = useNavigate();

  const GET_CATEGORIES_LIST = async (limit, offset) => {
    setIsLoading(true);
    await GET(
      `${EntityConstants.DISCOVERY_DEFAULT_CATEGORY}?limit=${limit}&offset=${offset}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCount(data.count);
        if (offset === 0) {
          setRespCategoriesList(data.results);
          setFullCategoriesList(data.results);
        } else {
          setRespCategoriesList((prevList) => [...prevList, ...data.results]);
        }
        setCategoriesList((prevList) => [...prevList, ...data.results]);
        setOffset(offset);
      })
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error(error);
      })
      .then(() => setIsLoading(false));
  };

  const SEARCH = async (e) => {
    const searchTerm = e.target.value.trim();

    if (searchTerm.length > 2) {
      setIsSearching(true);
      setIsSearched(false);
      setNoResults(false);

      try {
        const response = await GET(
          `${EntityConstants.DISCOVERY_SEARCH}${searchTerm}`
        );
        const data = await response.json();
        const categories = data.categories || [];
        setRespCategoriesList(categories);
        setCategoriesList(categories);
        setCount(categories.length);
        setIsSearched(true);

        if (categories.length === 0) {
          setNoResults(true);
        }
      } catch (error) {
        console.error("Error in search:", error);
        setRespCategoriesList([]);
      } finally {
        setIsSearching(false);
      }
    } else {
      // Reset when the search is cleared
      setRespCategoriesList(fullCategoriesList);
      setCategoriesList([]);
      setOffset(0); // Reset offset
      setIsSearched(false);
      setIsSearching(false);
      setNoResults(false);

      // Fetch the initial category list again
      GET_CATEGORIES_LIST(limit, 0);
    }
  };

  useEffect(() => {
    const updateCardsPerRow = () => {
      if (containerRef.current) {
        const calculatedCardsPerRow = CalculateCardsPerRow(
          containerRef,
          cardWidth,
          gap
        );
        if (calculatedCardsPerRow < 5) {
          setLimit(4);
        } else {
          setLimit(calculatedCardsPerRow * 2);
        }
      }
    };

    updateCardsPerRow();
    // window.addEventListener('resize', updateCardsPerRow);
    // return () => window.removeEventListener('resize', updateCardsPerRow);
  }, [cardWidth, gap]);

  useEffect(() => {
    limit > 0 && GET_CATEGORIES_LIST(limit, offset);
  }, [limit]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (respCategoriesList) {
      setCategoriesList([...respCategoriesList]);
    }
  }, [respCategoriesList]);

  useEffect(() => {
    if (
      authContext.hasDiscoveryReachedBottom &&
      !isSearched &&
      categoriesList.length < count
    ) {
      // The user has reached the bottom of the page
      // You can load more content or trigger some action here
      GET_CATEGORIES_LIST(limit, offset + limit);
    }
  }, [authContext.hasDiscoveryReachedBottom]);
  return (
    <div>
      <div className="h-full">
        <div className="bxm cbd font-CfPrimaryFont bg-cfSecondary">
          <div className="flex flex-col items-center w-full min-h-screen">
            <div
              className={`w-full bg-cfSearchContainer flex justify-center py-[0.8rem] px-4`}
            >
              <div
                className={`flex flex-col sm:flex-row items-start sm:items-center justify-between w-full`}
              >
                <div className="flex items-center gap-2 text-cfHeadingText font-semibold text-lg self-center md:self-start my-auto">
                  <img
                    src={ArrowLeftIcon}
                    alt=""
                    onClick={() => navigate(-1)}
                    className="cursor-pointer"
                  />
                  <p>Categories</p>
                </div>
                {/* Search */}
                <form className=" bg-cfSecondary p-2 rounded-lg w-full sm:w-[25rem] self-center ml-[0.630rem] md:self-end md:m-0">
                  <input
                    type="text"
                    name="search"
                    id="search"
                    onChange={(e) => SEARCH(e)}
                    placeholder="Search in categories"
                    className="outline-none border-none p-0 placeholder:text-sm w-full"
                    style={{ fontSize: "0.875rem", lineHeight: "1.25rem" }}
                  />
                </form>
              </div>
            </div>
            <div
              ref={containerRef}
              className="flex items-center flex-wrap gap-6 w-full p-4"
            >
              {noResults ? (
                <p className="text-grey-50 font-semibold text-lg mt-4">
                  No results found
                </p>
              ) : (
                categoriesList.map(
                  (
                    {
                      category_id,
                      category_name,
                      category_description,
                      tags,
                      thumbnail_images,
                      created_at,
                      ranking,
                      credentials_count,
                    },
                    index
                  ) => (
                    <>
                      {credentials_count > 0 && (
                        <CategoryCard
                          key={index}
                          category_name={category_name}
                          category_description={category_description}
                          category_id={category_id}
                          tagsList={tags}
                          credentials_count={credentials_count}
                          thumbnail_image_1={
                            thumbnail_images?.length > 0 && thumbnail_images[0]
                          }
                          thumbnail_image_2={
                            thumbnail_images?.length > 0 && thumbnail_images[1]
                          }
                          thumbnail_image_3={
                            thumbnail_images?.length > 0 && thumbnail_images[2]
                          }
                        />
                      )}
                    </>
                  )
                )
              )}

              {isLoading && <CategoryCard />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
