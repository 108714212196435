import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import breadcrumbArrowBlack from "../../resources/icons/breadcrumbArrowBlack.svg";
import breadcrumbArrowGray from "../../resources/icons/breadcrumbArrowGray.svg";

const CfBreadcrumb = ({
  breadcrumbItems,
  isToggleOn,
  setIsToggleOn,
  marginTop,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <nav
      className="ls afa afp alj h-[4rem] bg-cfBreadcrumbBG"
      aria-label="Breadcrumb"
    >
      <ol
        role="list"
        className="flex flex-col sm:flex-row justify-between md:w-[37%]"
      >
        <div className="gs ls ti abe ard cex ddc">
          <li key="home" className="ls">
            <div className="ls yu">
              <a className="axk bks">
                <NavLink to={"/"} className={`hover:no-underline`}>
                  <span className="text-cfTextNavbarDisabled ">
                    {"Templates"}
                  </span>
                </NavLink>
              </a>
            </div>
          </li>
          {breadcrumbItems.map((item, index) => {
            return (
              <li key={item.title} className="ls">
                <div className="ls yu">
                  <img
                    src={
                      window.location.pathname === item.to
                        ? breadcrumbArrowBlack
                        : breadcrumbArrowGray
                    }
                    alt=""
                  />
                  <a className={`js avv avz axm bku`}>
                    <NavLink
                      className={`hover:no-underline ${
                        window.location.pathname === item.to
                          ? "font-bold text-cfTextBreadcrumbActive hover:text-cfTextNavbarActive"
                          : "text-cfTextNavbarDisabled"
                      }`}
                      to={item.to}
                    >
                      {" "}
                      {item.title}
                    </NavLink>
                  </a>
                </div>
              </li>
            );
          })}
        </div>
        <div className="w-[38%]">
          <div
            className={`flex xs:flex-row ml-[1rem] sm:ml-[0rem] ${marginTop} sm:mt-[1.19rem]`}
          >
            <label className="inline-flex cursor-pointer xs:pb-6 md:pb-0">
              <input
                type="checkbox"
                id="toggle-prefill"
                checked={isToggleOn}
                onChange={() => setIsToggleOn((prev) => !prev)}
                className="sr-only peer"
              />
              <div className="relative w-11 mb-4 md:mb-12 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer dark:bg-gray-200 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-cfPrimaryLight"></div>
              <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
            </label>
            <div className="flex flex-col ">
              <div className="font-medium text-slate-700">Prefill</div>
            </div>
          </div>
        </div>
      </ol>
    </nav>
  );
};

export default CfBreadcrumb;
