export const toastOptions = {
  position: "top-right",
  style: {
    padding: "16px",
    color: "#713200",
  },
  error: {
    style: {
      color: "#bd0000",
    },
    duration: 5 * 1000,
  },
  loading: {
    style: {
      color: "#499fda",
    },
    duration: 3 * 1000,
  },
  success: {
    style: {
      color: "#5CB46A",
    },
    duration: 3 * 1000,
  },
};
export const containerStyle = {
  top: 70,
  right: 10,
};
