import React, { useState } from "react";
import { FaFileExcel } from "react-icons/fa6";
import { CgAttachment } from "react-icons/cg";
import BtnSubmit from "../Buttons/BtnSubmit/BtnSubmit";
import { GET, POSTFORMDATA } from "../../service/CRUDService";
import EntityConstants from "../../configs/EntityConstants";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import AppConstants from "../../configs/AppConstants";

export const FileUpload = ({ toggleUploadMethod, transaction_name }) => {
  const { credential_template_id, issuer_credential_template_id } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    if (event.dataTransfer.files.length > 0) {
      validateFile(event.dataTransfer.files[0]);
    }
  };

  const MAX_FILE_SIZE_KB = 100;

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      validateFile(file);
    }
  };

  const validateFile = (file) => {
    // 10KB -MAX_FILE_SIZE_KB

    const fileSizeKiloBytes = file.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE_KB) {
      setErrorMsg(
        `File size exceeds the maximum limit (${MAX_FILE_SIZE_KB}KB)`
      );
      setIsSuccess(false);
      return;
    }

    setSelectedFile(file);
    setErrorMsg("");
    setIsSuccess(true);
  };

  const handleSubmit = async (event, transaction_name) => {
    try {
      event.preventDefault();
      if (!selectedFile) {
        setErrorMsg("Please choose a file");
        setIsSuccess(false);
        return;
      } else {
        setIsSubmitting(true);
        const form_data = new FormData();
        form_data.append("file", selectedFile);
        const response = await POSTFORMDATA(
          `${EntityConstants.POST_CREDENTIALS_BULK_ISSUANCE}${issuer_credential_template_id}`,
          form_data
        );
        const data = await response.json();

        if (response.ok) {
          // navigate to Bulk transaction page
          navigate(`/issuer/transaction/${data.transaction_id}`);
          toast.success(data.message);
          setIsSubmitting(false);
        } else {
          if (data.message && Array.isArray(data.message)) {
            data.message.forEach((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error("API request failed\nPlease retry...");
          }
          setIsSubmitting(false);
        }
      }
    } catch (error) {
      toast.error(error.message);
      setIsSubmitting(false);
    }
  };

  const downloadXLXS = async (template_id) => {
    GET(
      `${EntityConstants.GET_EMAIL_WHATSAPP_DEFAULT_DETAILS}${template_id}/subject?type=xlsx`
    )
      .then((data) => data.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `Sample_${template_id}.xlsx`;
        document.body.appendChild(link);

        // Simulate user click event
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <form
      className=""
      onSubmit={(event) => handleSubmit(event, transaction_name)}
      onDragEnter={(e) => handleDragEnter(e)}
      onDragOver={(e) => handleDragOver(e)}
      onDragLeave={() => handleDragLeave()}
      onDrop={(e) => handleDrop(e)}
    >
      <div>
        <ul title="Downloads" className="px-1">
          <li title="Sample XLSX with metadata for issuance.">
            <button
              onClick={() => downloadXLXS(credential_template_id)}
              download
              className="flex items-center justify-end gap-2 text-purple-700 hover:text-purple-500"
            >
              Download Sample XLSX <FaFileExcel />
            </button>
          </li>
        </ul>
      </div>
      <div className="flex flex-col">
        <label
          htmlFor="dropzone-file"
          className={`flex flex-col items-center justify-center h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 ${
            isDragging ? "border-blue-500" : ""
          }`}
          onDragEnter={(e) => handleDragEnter(e)}
          onDragOver={(e) => handleDragOver(e)}
          onDragLeave={() => handleDragLeave()}
          onDrop={(e) => handleDrop(e)}
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <p className="mb-2 text-sm text-gray-500">
              Drag and drop or,{" "}
              <span className="font-semibold text-cfPrimary">Browse</span>
            </p>
            <p className="text-xs text-gray-500">
              CSV, XLX or XLSX (MAX. {MAX_FILE_SIZE_KB}KB)
            </p>
            {selectedFile && (
              <p className="text-emerald-600 ">{selectedFile.name}</p>
            )}
            <p className="text-red-500">{errorMsg}</p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            className="hidden"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleFileChange}
          />
        </label>
        <BtnSubmit
          Display_Icon={CgAttachment}
          TriggerBtn={() => toggleUploadMethod(true)}
          DisplayText={"Upload and Issue"}
          type="submit"
        />
      </div>
      <div className="p-2">
        <p className="flex items-center  before:content-['*'] before:text-lg before:font-semibold before:text-red-600 text-black ">
          To issue single certificate&nbsp;
          <p
            className="text-cfPrimary cursor-pointer hover:underline hover:duration-75"
            onClick={() => toggleUploadMethod(false)}
          >
            Click here!
          </p>
        </p>
      </div>
      {isSubmitting && <FullScreenLoader />}
    </form>
  );
};
