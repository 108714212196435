import { Base64 } from "js-base64";

const HandleImgError = (e,thumbnail_image) => {
    console.log("Error loading image:", e);

    if (!e.target.src.includes("data:image/svg+xml;base64,")) {
      const svgContent = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a5" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="#7B7FFF"></stop><stop offset=".3" stop-color="#7B7FFF" stop-opacity=".9"></stop><stop offset=".6" stop-color="#7B7FFF" stop-opacity=".6"></stop><stop offset=".8" stop-color="#7B7FFF" stop-opacity=".3"></stop><stop offset="1" stop-color="#7B7FFF" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a5)" stroke-width="13" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#7B7FFF" stroke-width="13" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>`;

      // Convert SVG content to Base64 using js-base64
      const svgDataURL = `data:image/svg+xml;base64,${Base64.encode(
        svgContent
      )}`;

      // Set img src to SVG data URL
      e.target.src = svgDataURL;

      fetch(thumbnail_image)
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.text(); // Fetch image as text
        })
        .then((svgContent) => {
          // Convert SVG content to Base64 using js-base64
          const svgDataURL = `data:image/svg+xml;base64,${Base64.encode(
            svgContent
          )}`;

          // Set img src to SVG data URL
          e.target.src = svgDataURL;
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);

          // If status was not "ok", show the "Couldn't load image" SVG
          if (error.message.includes("HTTP error! Status:")) {
            const svgContent = `
              <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                <rect width="100%" height="100%" fill="#e7e7e7"/>
                <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="Arial" font-size="16" fill="#333">Couldn't load image</text>
              </svg>
            `;

            // Convert SVG content to Base64 using js-base64
            const svgDataURL = `data:image/svg+xml;base64,${Base64.encode(
              svgContent
            )}`;

            // Set img src to SVG data URL
            e.target.src = svgDataURL;
          }
        });
    }
  };
  export default HandleImgError;