import React, { Fragment } from 'react'; 
import { Dialog, Transition } from "@headlessui/react";
import DownloadIcon from "../../resources/icons/download-cloud.svg";
import AlertIcon from "../../resources/icons/alert-circle.svg";

const DownloadRequestModal = ({
    isOpen,
    isSuccess,
    message,
    header,
    onClose
}) => {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={onClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 top-20 bg-black/30 backdrop-blur-sm" />
                </Transition.Child>

                <div className="fixed inset-0 top-20 overflow-y-auto px-4 md:px-0 max-h-full">
                    <div className="flex items-center min-h-full justify-center text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-lg max-h-[80vh] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                                <div className="p-6 flex flex-col items-center justify-center min-h-[200px]">                                    
                                    <div className="flex justify-center items-center h-10">
                                        <img
                                            src={isSuccess ? DownloadIcon : AlertIcon}
                                            alt={isSuccess ? "Download" : "Alert"}
                                            className="w-12 h-12"
                                        />
                                    </div>

                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-bold text-gray-900 text-center mt-2"
                                        id="modal-title"
                                    >
                                        {header}
                                    </Dialog.Title>

                                    <Dialog.Description
                                        as="p"
                                        className="mt-2 text-gray-600 text-center"
                                        id="modal-description"
                                    >
                                        {message}
                                    </Dialog.Description>

                                    <div className="mt-6 flex justify-center">
                                        <button
                                            className={`px-4 py-2 rounded-lg text-white focus:outline-none ${isSuccess
                                                ? 'bg-[#3538CD]' : 'bg-[#D94D20]'
                                            }`}
                                            onClick={onClose}
                                        >
                                            Okay
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default DownloadRequestModal;
