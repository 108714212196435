import React from "react";

const TopIssuers = ({ issuers }) => {
  const getInitials = (name) => {
    const nameParts = name.split(" ");
    if (nameParts.length === 1) return nameParts[0][0];
    return nameParts[0][0] + nameParts[nameParts.length - 1][0]; //? Get first and last initials
  };
  return (
    <div className="bg-white rounded-3xl flex flex-col gap-4">
      <div className="text-cfHeadingText pl-[1rem] pt-3 font-semibold text-lg">
        Top Issuers
      </div>
      <div
        className={`grid py-3 justify-center gap-8 ${
          issuers.length === 1
            ? "grid-cols-1"
            : issuers.length === 2
            ? "md:grid-cols-2 grid-cols-1"
            : issuers.length === 3
            ? "xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1"
            : "xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 grid-cols-1"
        }`}
      >
        {issuers.slice(0, 4).map((issuer, index) => (
          <div key={index} className="flex flex-col items-center">
            {issuer.imageUrl ? (
              <img
                src={issuer.imageUrl}
                alt={issuer.name}
                className="w-16 h-16 bg-[#f4f0fc] rounded-full mb-2"
              />
            ) : (
              <div className="w-16 h-16 rounded-full bg-[#f4f0fc] flex items-center justify-center text-cfPrimary text-xl font-bold mb-2">
                {getInitials(issuer.name)}
              </div>
            )}
            <div className="text-base flex justify-center font-semibold text-center">
              {issuer.name}
            </div>
            <div className="text-cfPrimaryLight">{issuer.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopIssuers;
