import React from "react";
import { useNavigate } from "react-router-dom";
import alertIcon from "../../resources/icons/alert-triangle.svg";

const ErrorHandling = ({ errorMessage }) => {
  const navigate = useNavigate();

  const handleDashboardClick = () => {
    navigate("/dashboard");
  };
  return (
    <div className="flex flex-col flex-wrap sm:mt-0 xl:mt-6 w-full bg-white rounded-2xl">
      <div className="flex flex-col px-2 lg:px-0 my-4  justify-center items-center gap-5">
        <img
          alt="certificate-icon"
          src={alertIcon}
          height="80px"
          width="80px"
          className=""
        />
        <h1 className="text-cfHeadingText font-semibold text-center">
          Something went wrong
        </h1>
        <h3 className="text-xs font-bold text-center lg:text-md text-gray-500">
          {`An error occurred, and we couldn't fetch your ${errorMessage}.`}
        </h3>
        <button
          className="rounded-lg lg:rounded-xl items-center w-[8rem] h-[2rem] lg:w-[10rem] lg:h-[3rem] lg:px-0 bg-cfPrimary text-white font-medium lg:font-semibold text-sm lg:text-md"
          onClick={handleDashboardClick}
        >
          Try Again
        </button>
      </div>
    </div>
  );
};

export default ErrorHandling;
