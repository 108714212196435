import React from "react";

const BtnSubmit = ({ Display_Icon, TriggerBtn, DisplayText }) => {
  return (
    <button
      className="relative inline-flex items-center justify-start px-3 py-2 overflow-hidden font-medium transition-all bg-cfPrimary rounded-md group w-fit focus:outline-none group-hover:drop-shadow-md"
      onClick={TriggerBtn}
    >
      <span className="absolute top-0 right-0 inline-block w-3 h-3 transition-all duration-500 ease-in-out bg-cfPrimaryDark rounded group-hover:-mr-4 group-hover:-mt-4">
        <span className="absolute top-0 right-0 w-4 h-4 rotate-45 translate-x-1/2 -translate-y-1/2 bg-cfSecondary"></span>
      </span>
      <span className="absolute bottom-0 left-0 w-full h-full transition-all duration-500 ease-in-out delay-100 -translate-x-full translate-y-full bg-cfPrimaryDark rounded-2xl group-hover:mb-8 group-hover:translate-x-0"></span>
      <span className="relative flex items-center w-full text-left text-cfSecondary transition-colors duration-200 ease-in-out group-hover:text-cfSecondary">
        {DisplayText}{Display_Icon && <Display_Icon />}
      </span>
    </button>
  );
};

export default BtnSubmit;
