import React, { useEffect, useState } from "react";
import { Formio } from "@formio/react";
import { useNavigate, useParams } from "react-router-dom";
// import { useParams } from 'react-router-dom';
import EntityConstants from "../../configs/EntityConstants";
import toast from "react-hot-toast";
import { POST } from "../../service/CRUDService";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import CfLoader from "../CfLoader/CfLoader";
const FormioSingleUpload = ({
  toggleUploadMethod,
  URL,
  formFieldsChanged,
  transaction_name,
  isToggleOn,
}) => {
  const { _1, credential_template_id, issuer_credential_template_id } =
    useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const onSubmitHandle = async (transaction_name, sub) => {
    //Submit-> only Preview for Submit form
    console.log("transaction_namesfdjgsfjdsfdsfjk : ", transaction_name);
    const payload = sub;
    try {
      setIsSubmitting(true);
      // Make an API call here
      const response = await POST(
        `${EntityConstants.POST_CREDENTIALS_SINGLE_ISSUANCE}${issuer_credential_template_id}`,
        payload
      );
      const data = await response.json();
      if (response.ok) {
        setIsSubmitting(false);
        //* add toast notification here under field 'message'
        toast.success(data.message);
        //? after toster
        //!redirect to home
        navigate(`/issuer/transaction/${data.transaction_id}`);
      } else {
        if (data.message && Array.isArray(data.message)) {
          data.message.forEach((msg) => {
            toast.error(msg);
          });
        } else {
          toast.error("API request failed\nPlease retry...");
        }
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    URL &&
      fetch(URL)
        .then((resp) => resp.json())
        .then((data) => {
          transaction_name &&
            Formio.createForm(document.getElementById("formio"), data, {}).then(
              (form) => {
                // form.language = i18n.language
                form.submission = {
                  data: isToggleOn
                    ? {
                        ...URL.data,
                      }
                    : {},
                };
                form.on("change", formFieldsChanged);
                form.on("submit", (sub) =>
                  onSubmitHandle(transaction_name, sub)
                );

                // Changes the language of the i18Next framework.

                setIsLoading(false);
              }
            );
        });
  }, [URL, transaction_name, isToggleOn]);
  return (
    <div className="w-full">
      <div id="formio" className="flex flex-col w-full ">
        <div className="flex justify-center items-center ">
          <CfLoader />
        </div>
      </div>
      <div className="p-2">
        <p className="flex items-center  before:content-['*'] before:text-lg before:font-semibold before:text-red-600 drop-shadow-lg text-black">
          To issue multiple certificates&nbsp;
          <p
            className="text-cfPrimary cursor-pointer hover:underline hover:duration-75"
            onClick={() => toggleUploadMethod(true)}
          >
            Click here!
          </p>
        </p>
      </div>
      {isSubmitting && <FullScreenLoader />}
    </div>
  );
};

export default FormioSingleUpload;
