import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import profileIcon from "../../resources/icons/id-card.svg";
import userIcon from "../../resources/icons/user-icon.svg";
import crossIcon from "../../resources/icons/cross-icon.svg";
import dangerIcon from "../../resources/icons/danger-icon.svg";
import podcastIcon from "../../resources/icons/podcast-icon.svg";
import Chart from "react-apexcharts";
import { GET } from "../../service/CRUDService";
import EntityConstants from "../../configs/EntityConstants";
import TopIssuers from "../../components/DashboardPageComponents/TopIssuers";
import ErrorHandling from "../../components/DashboardPageComponents/ErrorHandling";
import CredentialsDetails from "../../components/DashboardPageComponents/CredentialsDetails";
import {
  getColumnChartSeries,
  getColumnChartOptions,
} from "../../components/DashboardPageComponents/BarChart";
import {
  getPieChartOptions,
  getPieChartSeries,
} from "../../components/DashboardPageComponents/PieChart";

const Dashboard = () => {
  const navigate = useNavigate();

  const [totalCredentials, setTotalCredentials] = useState(null);
  const [totalHolders, setTotalHolders] = useState(null);
  const [totalExpired, setTotalExpired] = useState(null);
  const [totalRevoked, setTotalRevoked] = useState(null);
  const [credentialIssuedMonth, setCredentialIssuedMonth] = useState({});
  const [credentialPerMonth, setCredentialPerMonth] = useState({});
  const [issuerList, setIssuerList] = useState([]);
  const [credentialDistribution, setCredentialDistribution] = useState(null);
  const [isCredentialsError, setIsCredentialsError] = useState(false);
  const [isBarChartError, setIsBarChartError] = useState(false);
  const [isPieChartError, setIsPieChartError] = useState(false);
  const [isIssuerListError, setIsIssuerListError] = useState(false);

  useEffect(() => {
    GET(`${EntityConstants.GET_CREDENTIALS_COUNTS}`)
      .then((response) => response.json())
      .then((data) => {
        setTotalCredentials(data.total_credentials_issued);
        setTotalHolders(data.total_holders);
        setTotalExpired(data.total_expired_credentials);
        setTotalRevoked(data.total_revoked_credentials);
      })
      .catch((error) => {
        console.error("Error fetching the data:", error);
        setIsCredentialsError(true); // Set error flag if the request fails
      });
  }, []);

  useEffect(() => {
    GET(`${EntityConstants.GET_CREDENTIALS_BAR_CHART}`)
      .then((response) => response.json())
      .then((data) => {
        setCredentialIssuedMonth(data.months);
        setCredentialPerMonth(data.counts);
      })
      .catch((error) => {
        console.error("Error fetching bar chart data:", error);
        setIsBarChartError(true);
      });
  }, []);

  useEffect(() => {
    GET(`${EntityConstants.GET_CREDENTIALS_PIE_CHART_STATUS}`)
      .then((response) => response.json())
      .then((data) => {
        setCredentialDistribution(data);
      })
      .catch((error) => {
        console.error("Error fetching pie chart data:", error);
        setIsPieChartError(true);
      });
  }, []);

  useEffect(() => {
    GET(`${EntityConstants.GET_CREDENTIALS_ISSUERS_LIST}?limit=4&offset=0`)
      .then((response) => response.json())
      .then((data) => {
        const formattedIssuers = data.results.map((issuer) => ({
          name: issuer.issuer_name,
          value: issuer.issued_credentials_counts,
          imageUrl: issuer.issuer_profile_image || "",
        }));
        setIssuerList(formattedIssuers);
      })
      .catch((error) => {
        console.error("Error fetching issuer list:", error);
        setIsIssuerListError(true);
      });
  }, []);

  const handleTemplatesClick = () => {
    navigate("/discovery");
  };

  const months = credentialIssuedMonth;
  const issuedCredentials = credentialPerMonth;

  const columnChartSeries = getColumnChartSeries(issuedCredentials);
  const columnChartOptions = getColumnChartOptions(months);

  const pieChartSeries = getPieChartSeries(credentialDistribution);
  const pieChartOptions = getPieChartOptions();

  return (
    <div className="w-full px-6 py-6 mx-auto">
      {/* First Row */}
      <div className="flex flex-col 2xl:flex-row w-full gap-0 xl:gap-6 2xl:gap-0">
        <div className="flex flex-col lg:flex-row lg:flex-wrap xl:flex-nowrap w-full 2xl:w-[50%]">
          <div className="w-full max-w-full mb-6 lg:w-1/2 xl:mb-0 xl:w-full">
            <CredentialsDetails
              credentialDescription="Total Holders"
              credentialNumber={totalHolders}
              imageAlt="user-icon"
              imageSrc={userIcon}
              backgroundColor="#E9F8FF"
            />
          </div>

          <div className="w-full max-w-full sm:pl-0 lg:pl-3 sm:pr-0 2xl:pr-3 mb-6 lg:w-1/2 xl:mb-0 xl:w-full 2xl:w-full">
            <CredentialsDetails
              credentialDescription="Total Credentials"
              credentialNumber={totalCredentials}
              imageAlt="profile-icon"
              imageSrc={profileIcon}
              backgroundColor="#f4f0fc"
            />
          </div>
        </div>

        {/* Second Row */}
        <div className="flex flex-col lg:flex-row lg:flex-wrap xl:flex-nowrap w-full 2xl:w-[50%]">
          <div className="w-full max-w-full mb-6 lg:w-1/2 xl:mb-0 xl:w-full 2xl:w-full">
            <CredentialsDetails
              credentialDescription="Total Expired"
              credentialNumber={totalExpired}
              imageAlt="cross-icon"
              imageSrc={crossIcon}
              backgroundColor="#F6F6F6"
            />
          </div>

          <div className="w-full max-w-full sm:px-0 lg:pl-3 mb-6 lg:w-1/2 xl:mb-0 xl:w-full 2xl:w-full">
            <CredentialsDetails
              credentialDescription="Total Revoked"
              credentialNumber={totalRevoked}
              imageAlt="danger-icon"
              imageSrc={dangerIcon}
              backgroundColor="#fceeef"
            />
          </div>
        </div>
      </div>
      {totalCredentials === "0" ? (
        <div className="flex flex-col flex-wrap sm:mt-0 xl:mt-6 w-full bg-white min-h-screen rounded-2xl">
          <div className="flex flex-col px-2 lg:px-0 mt-20 md:mt-36 justify-center items-center gap-5">
            <img
              alt="certificate-icon"
              src={podcastIcon}
              height="80px"
              width="80px"
              className=""
            />
            <h1 className="text-cfHeadingText font-semibold text-center">
              No credentials issued
            </h1>
            <h3 className="text-xs font-bold text-center lg:text-md text-gray-500">
              Select one of the templates and issue a credential
            </h3>
            <button
              className="rounded-lg lg:rounded-xl items-center w-[8rem] h-[2rem] lg:w-[10rem] lg:h-[3rem] lg:px-0 bg-cfPrimary text-white font-medium lg:font-semibold text-sm lg:text-md"
              onClick={handleTemplatesClick}
            >
              See Templates
            </button>
          </div>
        </div>
      ) : (
        <div>
          {(totalCredentials !== "0" ||
            isBarChartError ||
            isPieChartError ||
            isCredentialsError) && (
            <div>
              <div className="flex flex-col xl:flex-row sm:mt-0 gap-3 xl:mt-6 w-full rounded-2xl">
                <div className="w-full xl:w-[51%] 2xl:w-[49.5%] bg-white rounded-3xl pt-3 pl-[0.5rem]">
                  <div className="text-cfHeadingText font-semibold text-lg pl-[0.75rem]">
                    Issuance Trend
                  </div>
                  {isBarChartError ? (
                    <ErrorHandling errorMessage={"credentials"} />
                  ) : (
                    <div className="flex justify-center">
                      {columnChartSeries && columnChartOptions && (
                        <Chart
                          options={columnChartOptions}
                          series={columnChartSeries}
                          type="bar"
                          className="w-[240%] h-400"
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="bg-white w-full xl:w-[49%] 2xl:w-[50.5%] pb-4 xl:pb-0 rounded-3xl flex flex-col">
                  <div className="flex justify-start xl:justify-end pt-3 pl-3 pr-3 text-cfHeadingText font-semibold text-lg">
                    Credential Status
                  </div>
                  {isPieChartError ? (
                    <ErrorHandling errorMessage={"credential statuses"} />
                  ) : (
                    <div className="flex flex-col md:flex-row h-[90%] pt-4 xl:pt-0 justify-center items-center bg-white w-full">
                      <div className="flex w-[70%]">
                        {pieChartSeries && pieChartOptions && (
                          <Chart
                            options={pieChartOptions}
                            series={pieChartSeries}
                            type="pie"
                            className="w-[100%] h-350"
                          />
                        )}
                      </div>
                      <div>
                        <div className="flex items-center">
                          <div className="w-5 inline-block h-5 mr-2 text-center rounded-circle bg-[#3366CC]"></div>
                          <div className="text-sm text-gray-500">Issued</div>
                        </div>
                        <div className="flex mt-2 items-center">
                          <div className="w-5 inline-block h-5 mr-2 text-center rounded-circle bg-[#DC3912]"></div>
                          <div className="text-sm text-gray-500">Revoked</div>
                        </div>
                        <div className="flex mt-2 items-center">
                          <div className="w-5 inline-block h-5 mr-2 text-center rounded-circle bg-[#FF9900]"></div>
                          <div className="text-sm text-gray-500">Failed</div>
                        </div>
                        <div className="flex mt-2 items-center">
                          <div className="w-5 inline-block h-5 mr-2 text-center rounded-circle bg-[#b9b9b9]"></div>
                          <div className="text-sm text-gray-500">Expired</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-6 xl:mt-6 w-full bg-white rounded-3xl">
                {isIssuerListError ? (
                  <ErrorHandling errorMessage={"issuers list"} />
                ) : (
                  <div>
                    <TopIssuers issuers={issuerList} />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
