import React, { useEffect, useRef, useState } from "react";
import BackArrow from "../../resources/icons/chevron-left.svg";
import EntityConstants from "../../configs/EntityConstants";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { GET } from "../../service/CRUDService";
import DownloadFile from "../../service/DownloadFile";
import toast from "react-hot-toast";

import Download from "../../resources/icons/download-01.svg";
import DownloadDisabled from "../../resources/icons/download-01-disabled.svg";
import BackArrowSquare from "../../resources/icons/arrow-square-up-right.svg";
import DotMoreOptions from "../../resources/icons/dots-horizontal.svg";
import DiabledDotMoreOptions from "../../resources/icons/disabled-dots-horizontal.svg";
import BackArrowSquareDisabled from "../../resources/icons/arrow-square-up-right-disabled.svg";
import CredentialPreviewModal from "../../common/CredentialPreviewModal/CredentialPreviewModal";
import CredentialRevokeModal from "../../common/CredentialRevokeModal/CredentialRevokeModal";
import revokeButton from "../../resources/icons/revokeButton.svg";
import disabledRevokeButton from "../../resources/icons/disabledRevokeButton.svg";
import JsonPreviewModal from "../../common/JsonPreviewModal/JsonPreviewModal";
import { Menu } from "@headlessui/react";
import DownloadRequestModal from "../../common/DownloadRequestModal/DownloadRequestModal";

const BATCH_TRANSACTION_POLLING_TIME_INTERVAL = 5000;
const BatchTransaction = () => {
  const navigate = useNavigate();
  const { transaction_id, transaction_name } = useParams();

  const location = useLocation();

  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setlimit] = useState(10);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenJSON, setIsOpenJSON] = useState(false);
  const [currentCredentialId, setCurrentCredentialId] = useState(null);
  const [currentCredentialIdJsonModal, setCurrentCredentialIdJsonModal] =
    useState(null);
  // const [printScreen, setPrintScreen] = useState(false); // !This is for print

  const [detailsList, setDetailsList] = useState([]);

  const [isLoadingcontentList, setIsLoadingcontentList] = useState(true);
  const customElementRef = useRef(null);
  const [showModal, setShowModal] = useState("");
  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);
  const [isStatus, setIsStatus] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(null);
  const [dataLoadCount, setDataLoadCount] = useState(0);
  const [title, setTitle] = useState("");

  const [isDownloadRequestModalOpen, setIsDownloadRequestModalOpen] = useState(false);
  const [downloadRequestModalMessage, setDownloadRequestModalMessage] = useState('');
  const [downloadRequestModalHeader, setDownloadRequestModalHeader] = useState('');
  const [isDownloadRequestSuccess, setIsDownloadRequestSuccess] = useState(false);

  const toggleModal = (credential_id) => {
    setIsModalOpen(credential_id);
  };

  const toggleNotificationModal = (val) => {
    setShowModal(val);
  };

  useEffect(() => {
    transaction_id && GET_ALL_TRANSACTIONS_LIST(limit, offset);
  }, [transaction_id]);

  const SEARCH = (e) => {
    e.preventDefault();
    console.log(e);
  };

  const GET_ALL_TRANSACTIONS_LIST = async (limit, offset) => {
    try {
      const response = await GET(
        `${EntityConstants.GET_USER_BULK_TRANSACTION_DETAILS}/${transaction_id}?offset=${offset}&limit=${limit}&statuses=Failed,Revoked,Issued,Sent`
      );

      if (response.ok) {
        const data = await response.json();
        count !== data.count && setCount(data.count);
        setOffset(offset);
        setDetailsList([...data.results]);
        dataLoadCount < 1 && setTitle([data.results[0].certificate_name]);
        setPrevious(data.previous);
        setNext(data.next);
        setIsStatus(data.status);
        data.results.length !== 0 && setIsLoadingcontentList(false);
      } else {
        console.log(response?.error);
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("Failed to fetch bulk data:", error);
    }
  };

  const CtaList = (status) => [
    {
      name: "BackArrowSquare",
      iconActive: (
        <img src={BackArrowSquare} alt="" srcset="" title="Preview" />
      ),
      iconDisabled: <img src={BackArrowSquareDisabled} alt="" srcset="" />,
      onClick: (credential_id) => {
        openCloseModal(credential_id);
      },
      disabled: status === "Failed",
    },
    {
      name: "Download",
      iconActive: <img src={Download} alt="" srcset="" title="Download" />,
      iconDisabled: <img src={DownloadDisabled} alt="" srcset="" />,
      onClick: (credential_id) =>
        DownloadFile(EntityConstants.DOWNLOAD_SVG_AS_PNG, credential_id),
      disabled: status === "Failed",
    },
    {
      name: "Revoke",
      iconActive: (
        <img
          src={revokeButton}
          height={27}
          width={27}
          alt=""
          srcSet=""
          title="Revoke"
        />
      ),
      iconDisabled: (
        <img
          src={disabledRevokeButton}
          height={27}
          width={27}
          alt=""
          srcSet=""
        />
      ),
      onClick: (credential_id) => {
        toggleModal(credential_id);
      },
      disabled: status === "Revoked" || status === "Failed",
    },
  ];

  const handleDownloadZip = async (event) => {
    event.stopPropagation();
    console.log(
      "The EntityConstants.DOWNLOAD_SVG_AS_ZIP, transaction_id : ",
      EntityConstants.DOWNLOAD_SVG_AS_ZIP,
      " ",
      transaction_id
    );
    await DownloadFile(EntityConstants.DOWNLOAD_SVG_AS_ZIP, transaction_id, true, showMessageModal);
  };

  const showMessageModal = (isSuccess, status, message) => {
    setIsDownloadRequestSuccess(isSuccess)
    setDownloadRequestModalHeader(status)
    setDownloadRequestModalMessage(message);
    setIsDownloadRequestModalOpen(true);
  };

  const openCloseModal = (credential_id) => {
    if (isOpen && currentCredentialId === credential_id) {
      setIsOpen(false);
      setCurrentCredentialId(null);
    } else {
      setIsOpen(true);
      setCurrentCredentialId(credential_id);
    }
  };

  const openCloseJsonModal = (credential_id) => {
    if (isOpenJSON && currentCredentialIdJsonModal === credential_id) {
      setIsOpenJSON(false);
      setCurrentCredentialIdJsonModal(null);
    } else {
      setIsOpenJSON(true);
      setCurrentCredentialIdJsonModal(credential_id);
    }
  };
  useEffect(() => {
    let pollingInterval;
    if (
      detailsList.some((credential) => !credential.status) ||
      detailsList.length < 1 ||
      isStatus === "In Progress"
    ) {
      pollingInterval = setInterval(() => {
        setDataLoadCount((prev) => prev + 1);
        GET_ALL_TRANSACTIONS_LIST(limit, offset);
      }, BATCH_TRANSACTION_POLLING_TIME_INTERVAL); // 5-second interval
    }
    return () => {
      clearInterval(pollingInterval); // Clear interval on cleanup
    };
  }, [detailsList, offset]);
  return (
    <div className="mt-20 bg-cfSecondary min-h-screen">
      <div className="flex w-full justify-between py-[2.625rem] px-[0.75rem]">
        <div className=" flex items-center text-[#101828] font-bold ">
          <img
            src={BackArrow}
            className="cursor-pointer"
            alt=""
            onClick={() => navigate(-1)}
          />
          <p>{title}</p>
        </div>

        {count > 1 && (
          <div
            onClick={(e) => handleDownloadZip(e)}
            className="cursor-pointer py-[0.875rem] px-[2.063rem] font-semibold rounded-lg bg-[#3538CD] text-cfSecondary"
          >
            <p>Download all certificates</p>
          </div>
        )}
      </div>
      <DownloadRequestModal isOpen={isDownloadRequestModalOpen}
                header={downloadRequestModalHeader}
                message={downloadRequestModalMessage}
                isSuccess={isDownloadRequestSuccess}
                onClose={() => setIsDownloadRequestModalOpen(false)} />
      {isModalOpen && (
        <CredentialRevokeModal
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          GET_ALL_TRANSACTIONS_LIST={() =>
            GET_ALL_TRANSACTIONS_LIST(limit, offset)
          }
        />
      )}

      <div class="overflow-x-auto  z-10">
        {detailsList.length === 0 && (
          <table class="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead class="text-xs text-[#344054] font-bold bg-[#E0EAFF]">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Name
                </th>
                <th scope="col" class="px-6 py-3">
                  Email ID
                </th>
                <th scope="col" class="px-6 py-3">
                  Phone number
                </th>
                <th scope="col" class="px-6 py-3">
                  WhatsApp number
                </th>
                <th scope="col" class="px-6 py-3">
                  Status
                </th>
                <th scope="col" class="px-6 py-3">
                  Preview, Download and Revoke
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="border-b hover:bg-[#F9FAFB]">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap animate-pulse"
                >
                  Loading . . .
                </th>
                <td class="px-6 py-4 animate-pulse">Loading . . .</td>
                <td class="px-6 py-4 animate-pulse">Loading . . .</td>
                <td class="px-6 py-4 animate-pulse">Loading . . .</td>
                <td class=" py-4">
                  <p
                    className={`w-fit font-semibold text-[#EBF8E7] px-[1.125rem] ml-2.5 py-[0.125rem] text-cfSecondary rounded-full bg-gray-300 animate-pulse`}
                  >
                    Loading . . .
                  </p>
                </td>
                <td class="px-7 py-4">
                  <div className="flex items-center gap-[2.125rem]">
                    {CtaList(detailsList.status).map(
                      (
                        { _name, _iconActive, iconDisabled, _onClick },
                        index
                      ) => (
                        <div
                          className="flex items-center gap-[0.5rem] w-6 cursor-not-allowed"
                          key={index}
                        >
                          {iconDisabled}
                        </div>
                      )
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}
        {detailsList.length > 0 && (
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 z-20">
            <thead class="text-xs text-[#344054] font-bold bg-[#E0EAFF]">
              <tr>
                <th scope="col" class="px-6 py-3 text-center">
                  Name
                </th>
                <th scope="col" class="px-6 py-3 text-center">
                  Email ID
                </th>
                <th scope="col" class="px-6 py-3">
                  Phone number
                </th>
                <th scope="col" class="px-6 py-3">
                  WhatsApp number
                </th>
                <th scope="col" class="px-6 py-3">
                  Status
                </th>
                <th scope="col" class="px-6 py-3">
                  Preview, Download and Revoke
                </th>
                <th scope="col" class="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {detailsList.map(
                (
                  {
                    credential_id,
                    approved_by,
                    issuer_name,
                    org_name,
                    public_verify_url,
                    email_id,
                    mobile_number,
                    recipient_name,
                    status,
                    updated_at,
                    svg_url,
                    thumbnail_url,
                    whatsapp_number,
                  },
                  index
                ) => (
                  <tr class="border-b hover:bg-[#F9FAFB]" key={credential_id}>
                    <th
                      scope="row"
                      class="px-6 py-4 text-center font-medium text-gray-900 whitespace-nowrap"
                    >
                      {recipient_name ? (
                        recipient_name
                      ) : (
                        <span className="w-fit font-semibold text-[#EBF8E7] px-[1.125rem]  py-[0.125rem] text-cfSecondary rounded-full bg-gray-300">
                          Unavailable
                        </span>
                      )}
                    </th>
                    <td class="px-6 py-4 text-center">
                      {email_id ? (
                        email_id
                      ) : (
                        <span className="w-fit font-semibold text-[#EBF8E7] px-[1.125rem]  py-[0.125rem] text-cfSecondary rounded-full bg-gray-300">
                          Unavailable
                        </span>
                      )}
                    </td>
                    <td class="px-6 py-4">
                      {mobile_number ? (
                        mobile_number
                      ) : (
                        <span className="w-fit font-semibold text-[#EBF8E7] px-[1.125rem]  py-[0.125rem] text-cfSecondary rounded-full bg-gray-300">
                          Unavailable
                        </span>
                      )}
                    </td>
                    <td class="px-6 py-4">
                      {whatsapp_number ? (
                        whatsapp_number
                      ) : (
                        <span className="w-fit font-semibold text-[#EBF8E7] px-[1.125rem]  py-[0.125rem] text-cfSecondary rounded-full bg-gray-300">
                          Unavailable
                        </span>
                      )}
                    </td>
                    <td class=" py-4">
                      <p
                        className={`w-fit font-semibold text-[#EBF8E7] px-[1.125rem] ml-2.5 py-[0.125rem] text-cfSecondary rounded-full ${
                          status === "Issued"
                            ? "bg-cfIssuedCredentialTemplateCardStatusDoneBG"
                            : status === "Failed"
                            ? "bg-cfIssuedCredentialTemplateCardStatusUnderReviewBG"
                            : status === "Revoked"
                            ? "bg-cfIssuedCredentialTemplateCardStatusRevokedBG"
                            : !status && " bg-gray-300 "
                        }`}
                      >
                        {status ? status : "Unavailable"}
                      </p>
                    </td>
                    <td class="px-6 py-4 z-50">
                      <div className="flex items-center gap-[2.125rem]">
                        {CtaList(status).map(
                          (
                            {
                              _name,
                              iconActive,
                              iconDisabled,
                              onClick,
                              disabled,
                            },
                            index
                          ) => (
                            <div
                              className={`flex items-center gap-[0.5rem] w-6 z-100 ${
                                disabled
                                  ? "cursor-not-allowed"
                                  : "cursor-pointer"
                              }`}
                              key={index}
                              onClick={
                                !disabled ? () => onClick(credential_id) : null
                              }
                            >
                              {disabled ? iconDisabled : iconActive}
                            </div>
                          )
                        )}
                        <Menu as="div" className="relative ">
                          <div>
                            <Menu.Button
                              className={`flex max-w-xs items-center text-md text-cfNavBarText focus:outline-none pr-4 ${
                                status === "Failed"
                                  ? "cursor-not-allowed"
                                  : "cursor-pointer"
                              }`}
                              disabled={status === "Failed"}
                            >
                              <img
                                src={
                                  status === "Failed"
                                    ? DiabledDotMoreOptions
                                    : DotMoreOptions
                                }
                                alt="More Options"
                              />
                            </Menu.Button>
                          </div>
                          {status !== "Failed" && (
                            <Menu.Items
                              className={`flex flex-col text-gray-500 absolute ${
                                detailsList.length === 1 ||
                                (detailsList.length > 1 &&
                                  (index === detailsList.length - 1 ||
                                    index === detailsList.length - 2))
                                  ? "left-10 -top-5   "
                                  : "right-0"
                              } z-10 px-2 text-md w-max origin-top-right rounded-md bg-cfSecondary py-1 shadow-lg ring-1 ring-cfSecondary ring-opacity-5 focus:outline-none`}
                            >
                              <div className="">
                                {/* <hr /> */}
                                <Menu.Item
                                  className="px-2 pb-1 py-2 cursor-pointer"
                                  onClick={() => {
                                    openCloseJsonModal(credential_id);
                                  }}
                                >
                                  <div className="hover:no-underline hover:text-gray-600 text-inherit flex items-center gap-1">
                                    View JSON
                                  </div>
                                </Menu.Item>
                                {/* <hr /> */}
                              </div>
                            </Menu.Items>
                          )}
                        </Menu>
                      </div>
                    </td>
                    <td class="px-6 py-4"></td>
                    {isOpen && currentCredentialId === credential_id && (
                      <CredentialPreviewModal
                        credential_id={credential_id}
                        approved_by={approved_by}
                        issuer_name={issuer_name}
                        org_name={org_name}
                        public_verify_url={public_verify_url}
                        email_id={email_id}
                        mobile_number={mobile_number}
                        recipient_name={recipient_name}
                        status={status}
                        updated_at={updated_at}
                        svg_url={svg_url}
                        thumbnail_url={thumbnail_url}
                        whatsapp_number={whatsapp_number}
                        openCloseModal={openCloseModal}
                        isOpen={isOpen}
                      />
                    )}
                    {isOpenJSON &&
                      currentCredentialIdJsonModal === credential_id && (
                        <JsonPreviewModal
                          credential_id={credential_id}
                          openCloseModal={openCloseJsonModal}
                          isOpen={isOpenJSON}
                        />
                      )}
                  </tr>
                )
              )}
            </tbody>
          </table>
        )}
      </div>
      <div className="flex items-center justify-between mt-6 px-8 w-full">
        <button
          onClick={() => GET_ALL_TRANSACTIONS_LIST(limit, offset - limit)}
          className="flex items-center justify-center px-5 py-2 w-36 text-sm active:text-gray-700 capitalize transition-colors duration-200 bg-cfSecondary border rounded-md gap-x-2 hover:bg-gray-100 disabled:text-gray-400"
          disabled={previous === null}
        >
          <span className="flex items-center justify-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 rtl:-scale-x-100"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
              />
            </svg>

            <span>previous</span>
          </span>
        </button>

        <button
          onClick={() => GET_ALL_TRANSACTIONS_LIST(limit, offset + limit)}
          className="flex items-center justify-center px-5 py-2 w-36 text-sm active:text-gray-700 capitalize transition-colors duration-200 bg-cfSecondary border rounded-md gap-x-2 hover:bg-gray-100 disabled:text-gray-400"
          disabled={next === null}
        >
          <span className="flex items-center justify-center gap-2">
            <span>Next</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 rtl:-scale-x-100"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
};

export default BatchTransaction;
