import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowLeftIcon from "../../resources/icons/arrow-narrow-left.svg";
import CredentialTemplateCard from "../../components/Cards/CredentialTemplateCard/CredentialTemplateCard";
import EntityConstants from "../../configs/EntityConstants";
import { GET } from "../../service/CRUDService";
import AuthContext from "../../store/auth-context";
import CalculateCardsPerRow from "../../service/CalculateCardsPerRow";

const AllCertificatesPerCategory = () => {
  const authContext = useContext(AuthContext);

  const { category_name, category_id } = useParams();

  const [limit, setLimit] = useState(0);
  const [offset, setOffset] = useState(0);
  const [contextList, setContextList] = useState([]); // [contextList]
  const [respContextList, setRespContextList] = useState([]); // [respContextList]
  const [count, setCount] = useState(0);
  const [categoryName, setCategoryName] = useState("");

  const [cardWidth, setCardWidth] = useState("19.125rem");
  const [gap, setGap] = useState("0.75rem");
  const containerRef = useRef(null);

  const [isSearching, setIsSearching] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const GET_CERTIFICATES_LIST = async (limit, offset) => {
    setIsLoading(true);
    await GET(
      `${EntityConstants.TEMPLATE_PER_CATEGORY}category_id=${category_id}&limit=${limit}&offset=${offset}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCount(data.count);
        setOffset(offset);
        setIsLoading(false);
        setRespContextList([...respContextList, ...data.results]);
      })
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error(error);
      })
      .then(() => setIsLoading(false));
  };

  const SEARCH = async (e) => {
    e.preventDefault();
    const searchTerm = e.target.value.trim();

    if (searchTerm.length > 2) {
      setIsSearching(true);
      try {
        const response = await GET(
          `${EntityConstants.DISCOVERY_SEARCH}${searchTerm}&category_id=${category_id}`
        );
        const data = await response.json();
        if (data.credentials) {
          setContextList(data.credentials);
          setIsSearched(true);
        } else {
          setContextList([]);
        }
      } catch (error) {
        console.error("Error during search:", error);
        setContextList([]);
      } finally {
        setIsSearching(false);
      }
    } else {
      setContextList([...respContextList]);
      setIsSearched(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    const updateCardsPerRow = () => {
      if (containerRef.current) {
        const calculatedCardsPerRow = CalculateCardsPerRow(
          containerRef,
          cardWidth,
          gap
        );
        if (calculatedCardsPerRow < 5) {
          setLimit(4);
        } else {
          setLimit(calculatedCardsPerRow);
        }
      }
    };

    updateCardsPerRow();
    // window.addEventListener('resize', updateCardsPerRow);
    // return () => window.removeEventListener('resize', updateCardsPerRow);
  }, [cardWidth, gap]);

  useEffect(() => {
    limit > 0 && GET_CERTIFICATES_LIST(limit, offset);
  }, [limit]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (respContextList) {
      setContextList([...respContextList]);
    }
  }, [respContextList]);

  useEffect(() => {
    if (
      authContext.hasDiscoveryReachedBottom &&
      !isSearched &&
      contextList.length < count
    ) {
      // The user has reached the bottom of the page
      // You can load more content or trigger some action here
      GET_CERTIFICATES_LIST(limit, offset + limit);
    }
  }, [authContext.hasDiscoveryReachedBottom]);
  useEffect(() => {
    setCategoryName(category_name.replace(/\+/g, " "));
  }, [category_name]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="h-full ">
        <div className="bxm cbd font-CfPrimaryFont bg-cfSecondary">
          <div className="flex flex-col items-center w-full mb-4 min-h-screen">
            <div
              className={`w-full bg-cfSearchContainer flex justify-center py-[0.8rem] px-4`}
            >
              <div
                className={`flex flex-col sm:flex-row items-start sm:items-center justify-between w-full`}
              >
                <div className="flex items-center gap-2 text-cfHeadingText font-semibold text-lg self-center md:self-start my-auto">
                  <img
                    src={ArrowLeftIcon}
                    alt=""
                    onClick={() => navigate(-1)}
                    className="cursor-pointer"
                  />
                  <p>{categoryName}</p>
                </div>
                {/* Search */}
                <form className=" bg-cfSecondary p-2 rounded-lg w-full sm:w-[25rem] self-center ml-[0.630rem] md:self-end md:m-0">
                  <input
                    type="text"
                    name="search"
                    id="search"
                    onChange={(e) => SEARCH(e)}
                    placeholder={`Search in ${categoryName}`}
                    className="outline-none border-none p-0 placeholder:text-sm w-full"
                    style={{ fontSize: "0.875rem", lineHeight: "1.25rem" }}
                  />
                </form>
              </div>
            </div>
            <div ref={containerRef} className="w-full">
              <div className="flex items-center flex-wrap gap-6 w-full p-4">
                {contextList.map(
                  (
                    {
                      credential_template_id,
                      name,
                      description,
                      tags,
                      thumbnail_svg_url,
                      _1,
                    },
                    index
                  ) => (
                    <CredentialTemplateCard
                      key={index}
                      name={name}
                      description={description}
                      credential_template_id={credential_template_id}
                      tagsList={tags}
                      thumbnail_image={thumbnail_svg_url}
                      navigate_url={`/certificate/0/${credential_template_id}`}
                    />
                  )
                )}
                {isSearched && contextList.length === 0 && (
                  <p className="text-grey-50 font-semibold text-lg mt-[1rem] ml-[2rem]">
                    No results found
                  </p>
                )}
              </div>
              {isLoading && <CredentialTemplateCard />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCertificatesPerCategory;
