import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useLocation, useParams } from "react-router-dom";
import EntityConstants from "../../configs/EntityConstants";
import { GET, POST } from "../../service/CRUDService";
import { imgRegex, whiteSpaceEmptyStrRegex } from "../../configs/App.Regex";
import { Formio } from "@formio/react";
import toast from "react-hot-toast";
import CfLoader from "../../common/CfLoader/CfLoader";
import { useNavigate } from "react-router-dom";

import { RxReload } from "react-icons/rx";

import { LuImageOff } from "react-icons/lu";

import CfBreadcrumb from "../../common/CfBreadcrumb/CfBreadcrumb";

import GenerateCertificate from "../../service/GenerateCertificate";
import AuthContext from "../../store/auth-context";
import LoginModel from "../../common/LoginModal/LoginModal";

const CertificateDetails = () => {
  const navigate = useNavigate();

  const { credential_template_id, issuer_credential_template_id } = useParams();
  const [certificateTitle, setCertificateTitle] = useState("");
  const [error, setError] = useState("");
  const [fieldsChanged, setFieldsChanged] = useState({});

  const [currentSVG, setCurrentSVG] = useState("");
  const [certificateData, setCertificateData] = useState();
  const [orgLogo, setOrgLogo] = useState("");
  const [signOrSignUp, setSignOrSignUp] = useState(true);
  const [triggerLoginModuleToggleValue, setTriggerLoginModuleToggleValue] =
    useState(false);
  const [customFieldChanged, setCustomFieldChanged] = useState({});
  const [isToggleOn, setIsToggleOn] = useState(false);

  const breadcrumbItems = [
    { title: "Edit", to: `/certificate/0/${credential_template_id}` },
  ];

  const location = useLocation();
  const authContext = useContext(AuthContext);

  const TriggerLoginModuleBtnFromModule = () => {
    setTriggerLoginModuleToggleValue(false);
  };

  const chooseSignUp_SignIn = (bool) => {
    setSignOrSignUp(bool);
  };
  const onSubmitHandle = async (sub, bool) => {
    //Submit-> only Preview for Submit form
    if (bool) {
      let payload = { ...sub };

      if (!payload.data.logo || payload.data.logo.length === 0) {
        //! If no logo is provided, use the org_logo from this API GET(EntityConstants.GET_ORG_DETAILS)
        if (orgLogo) {
          payload.data.logo = [
            {
              storage: "base64",
              name: "org_logo.svg",
              url: orgLogo,
              type: "image/svg+xml",
            },
          ];
        } else {
          try {
            const response = await GET(EntityConstants.GET_ORG_DETAILS);
            const data = await response.json();
            if (data.org_logo) {
              payload.data.logo = [
                {
                  storage: "base64",
                  name: "default_org_logo.svg",
                  url: data.org_logo,
                  type: "image/svg+xml",
                },
              ];
              toast.success("Default logo applied.");
            } else {
              if (data.message && Array.isArray(data.message)) {
                data.message.forEach((msg) => {
                  toast.error(msg);
                });
              } else {
                toast.error("API request failed\nPlease retry...");
              }
              return;
            }
          } catch (err) {
            toast.error("API request failed\nPlease retry...");
            return;
          }
        }
      }

      if (
        sessionStorage.getItem("certificate_title") &&
        sessionStorage.getItem("certificate_title") != "" &&
        !whiteSpaceEmptyStrRegex.test(
          sessionStorage.getItem("certificate_title")
        )
      ) {
        payload = {
          form_data: payload,
          credential_template_id: credential_template_id,
          certificate_name: sessionStorage.getItem("certificate_title"),
        };
        try {
          // Make an API call here
          const response = await POST(
            `${EntityConstants.POST_CREDENTIALS_TEMPLATE_DESIGN}${credential_template_id}/design`,
            payload
          );
          if (response.ok) {
            const data = await response.json();
            sessionStorage.setItem(
              "issuer_credential_template_id",
              data.issuer_credential_template_id
            );
            console.log(
              `/issuer/certificate/1/${data.credential_template_id}/${data.issuer_credential_template_id}`
            );
            navigate(
              `/issuer/certificate/1/${data.credential_template_id}/${data.issuer_credential_template_id}`
            );
            toast.success("Certificate Template Saved Successfully");
          } else {
            const errorData = await response.json();
            if (errorData.message && Array.isArray(errorData.message)) {
              errorData.message.forEach((msg) => {
                toast.error(msg);
              });
            } else {
              toast.error("API request failed\nPlease retry...");
            }
          }
        } catch (error) {
          console.error("An error occurred:", error);

          //* add toast notification here
        }
      }
    } else if (!bool) {
      setTriggerLoginModuleToggleValue(true);
    }
  };

  const GET_CERTIFICATE_DATA = (credential_template_id) => {
    GET(
      `${EntityConstants.GET_CREDENTIALS_TEMPLATE_DESIGN}${credential_template_id}/design`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
        } else {
          setCertificateData(data);
        }
      });
  };

  const formFieldsChanged = (fields) => {
    if (
      fields.changed?.component?.key !== undefined &&
      fields.changed?.value !== undefined
    ) {
      const fieldKey = fields.changed.component.key;
      if (fieldKey === "logo") {
        const logoValue = fields.changed.value;
        if (!logoValue || logoValue.length === 0 || !logoValue[0]?.url) {
          setOrgLogo(orgLogo);
        } else if (imgRegex.test(logoValue[0].url)) {
          // If the logo is valid, update the orgLogo state
          setOrgLogo(logoValue[0].url);
        }
      }
      if (
        fields.changed?.value[0]?.type &&
        imgRegex.test(fields.changed?.value[0].url)
      ) {
        isToggleOn
          ? setFieldsChanged((prevData) => ({
              ...prevData,
              // Add your new key-value pair here
              [fields.changed?.component?.key]: fields.changed?.value[0].url,
            }))
          : setCustomFieldChanged((prevData) => ({
              ...prevData,
              // Add your new key-value pair here
              [fields.changed?.component?.key]: fields.changed?.value[0].url,
            }));
      } else {
        isToggleOn
          ? setFieldsChanged((prevData) => ({
              ...prevData,
              // Add your new key-value pair here
              [fields.changed?.component?.key]: fields.changed?.value,
            }))
          : setCustomFieldChanged((prevData) => ({
              ...prevData,
              // Add your new key-value pair here
              [fields.changed?.component?.key]: fields.changed?.value,
            }));
      }
    } else if (fields?.data) {
      setFieldsChanged(fields.data);
    }
  };

  useEffect(() => {
    credential_template_id && GET_CERTIFICATE_DATA(credential_template_id);
  }, [credential_template_id]);

  const fetchSvg = async (certificateData) => {
    const { _1, template_variables_data, template_svg, _2 } = certificateData;
    try {
      const response = await fetch(`${template_svg}`);
      // const response = await fetch(`${template_svg}`);
      if (response.ok) {
        const svgContent = await response.text();
        setCurrentSVG(svgContent);
        setFieldsChanged(template_variables_data);
        //!injecting the svg content into the container
        const container = document.getElementById("certificate_container");
        container.style.filter = "drop-shadow(0 0 2px rgba(0, 0, 0, 0.264))";
        container.innerHTML = svgContent;
      } else if (response.status == 404) {
        const container = document.getElementById("certificate_container");
        ReactDOM.render(
          <div class="text-black flex justify-center w-full h-full text-lg">
            <div className="flex flex-col items-center gap-2 w-fit h-fit">
              <span className="flex items-center gap-2 w-fit h-fit  bg-gray-100 px-2 py-1 rounded-lg">
                <p>Coulden't find image..</p>
                <LuImageOff class="text-cfPrimaryCardHover " />
              </span>
              <span>
                <p>Please create template again...</p>
              </span>
            </div>
          </div>,
          container
        );
      } else {
        const container = document.getElementById("certificate_container");
        ReactDOM.render(
          <div class="text-black flex justify-center w-full h-full text-lg">
            <div
              className="flex items-center gap-2 w-fit h-fit rounded-lg cursor-pointer"
              onClick={() => {
                ReactDOM.render(
                  <span className="w-full flex justify-center">
                    <CfLoader />
                  </span>,
                  container
                );
                certificateData && fetchSvg(certificateData);
              }}
            >
              <p>Couldn't Load Image...</p>
              <RxReload class="text-cfPrimaryCardHover " />
            </div>
          </div>,
          container
        );
      }
    } catch (error) {
      console.error("Error loading SVG:", error);
    }
  };

  useEffect(() => {
    GET(EntityConstants.GET_ORG_DETAILS)
      .then((response) => response.json())
      .then((data) => {
        setOrgLogo(data.org_logo);
      })
      .catch((error) => {
        console.log("Error in GET_ORG_DETAILS : ", error);
        toast.error("API request failed...");
      });
  }, []);

  useEffect(() => {
    certificateData instanceof Object &&
      certificateData.hasOwnProperty("default_template_variables_data") &&
      fetchSvg(certificateData);
  }, [certificateData]);

  useEffect(() => {
    if (currentSVG) {
      const updatedFields = isToggleOn
        ? { ...fieldsChanged, logo: orgLogo }
        : { ...customFieldChanged, logo: orgLogo };

      const modifiedSVG = GenerateCertificate(currentSVG, updatedFields);
      document.getElementById("certificate_container").innerHTML = modifiedSVG;
    }
  }, [fieldsChanged, customFieldChanged, currentSVG, orgLogo, isToggleOn]);

  useEffect(() => {
    if (
      certificateTitle.length === 0 ||
      whiteSpaceEmptyStrRegex.test(certificateTitle)
    ) {
      setError("Certificate Name is required");
      sessionStorage.removeItem("certificate_title");
    } else {
      sessionStorage.setItem("certificate_title", certificateTitle);
      setError("");
    }
  }, [certificateTitle]);

  console.log("The certificateData is >>>> ", certificateData);

  useEffect(() => {
    certificateData?.form_io_url &&
      fetch(certificateData?.form_io_url)
        .then((resp) => resp.json())
        .then((data) => {
          certificateData?.form_io_url &&
            Formio.createForm(document.getElementById("formio"), data, {}).then(
              (form) => {
                form.ready.then(() => {
                  form.submission = {
                    data: isToggleOn
                      ? {
                          ...certificateData?.default_template_variables_data
                            ?.data,
                        }
                      : {},
                  };
                  form.on("change", formFieldsChanged);
                  form.on("submit", (sub) =>
                    onSubmitHandle(sub, authContext.isLoggedIn)
                  );
                });
              }
            );
          sessionStorage.removeItem("issuer_credential_template_id");
        })
        .catch((error) =>
          console.log("Error while hitting formio url: ", error)
        );
  }, [certificateData?.form_io_url, authContext.isLoggedIn, isToggleOn]);

  useEffect(() => {
    authContext.lastVisitedPathHandler(location.pathname);
  });
  return (
    <>
      {breadcrumbItems.length > 0 && (
        <CfBreadcrumb
          breadcrumbItems={breadcrumbItems}
          isToggleOn={isToggleOn}
          setIsToggleOn={setIsToggleOn}
          marginTop={"mt-[0.5rem]"}
        />
      )}
      <div className="flex w-full">
        <div className="flex flex-col w-full md:w-[38%] items-center flex-grow bg-cfSecondary min-h-full max-h-[82vh] overflow-y-auto">
          <div
            action=""
            className="flex flex-col w-full items-left px-3 pb-3 pt-10"
          >
            <p
              htmlFor="certificateName"
              className=" after:content-['*'] after:text-red-600"
            >
              Certificate Name
            </p>
            <span className="flex justify-between items-center focus-within:border-4 focus-within:border-cfCertificateNameInputBorder rounded-xl">
              <span className="flex justify-between items-center border-[1px] rounded-md p-1 w-full">
                <input
                  type="text"
                  name="certificateName"
                  className="border-none rounded-md w-full focus:outline-none"
                  id="certificateName"
                  onChange={(e) => setCertificateTitle(e.target.value)}
                  value={certificateTitle}
                />
              </span>
            </span>
            {error && <p className="text-red-600">{error}</p>}
          </div>
          <div className="w-full px-3">
            <div id="formio" className="w-full p-2 h-full">
              <div className="flex justify-center items-center ">
                <CfLoader />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mx-1 p-3">
          <div className="w-full">
            <div
              className="flex flex-col flex-shrink  max-h-[78vh]"
              id="certificate_container"
            >
              <CfLoader />
            </div>
          </div>
        </div>
        <LoginModel
          TriggerBtn={triggerLoginModuleToggleValue}
          TriggerModuleBtnFromModule={TriggerLoginModuleBtnFromModule}
          signOrSignUp={signOrSignUp}
          chooseSignUp_SignIn={chooseSignUp_SignIn}
          DisplayText=""
        />
      </div>
    </>
  );
};

export default CertificateDetails;
