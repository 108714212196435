const CalculateCardsPerRow = (containerRef, cardWidth, gap) => {
  if (!(containerRef && containerRef.current instanceof HTMLElement)) {
    throw new Error('Invalid containerRef. It must be a ref to a DOM element.');
  }

  if (typeof cardWidth !== 'string' && typeof cardWidth !== 'number') {
    throw new Error('Invalid cardWidth. It must be a string (e.g., "100px", "10rem", "1em") or a number.');
  }

  if (typeof gap !== 'string' && typeof gap !== 'number') {
    throw new Error('Invalid gap. It must be a string (e.g., "10px", "1rem", "1em") or a number.');
  }

  const parseValue = (value) => {
    if (typeof value === 'string') {
      const match = value.match(/^(\d*\.?\d+)(rem|em|px)?$/);
      if (match) {
        const numericValue = parseFloat(match[1]);
        const unit = match[2] || 'px';
        if (unit === 'px') {
          return numericValue;
        } else if (unit === 'rem') {
          return numericValue * 16;
        } else if (unit === 'em') {
          const fontSize = parseFloat(window.getComputedStyle(document.body).fontSize);
          return numericValue * fontSize;
        }
      } else {
        throw new Error('Invalid unit for value. Only use rem, em, px, or just a number (interpreted as px by default).');
      }
    } else {
      return value;
    }
  };

  const parsedCardWidth = parseValue(cardWidth);
  const parsedGap = parseValue(gap);

  if (containerRef.current) {
    const containerWidth = containerRef.current.offsetWidth;
    return Math.floor(containerWidth / (parsedCardWidth + parsedGap));
  }
  return 0;
};

export default CalculateCardsPerRow;
