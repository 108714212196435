import React, { useContext, useEffect, useState } from "react";
import DraftsCredentialTemplateCard from "../../components/Cards/DraftsCredentialTemplateCard/DraftsCredentialTemplateCard";
import { GET } from "../../service/CRUDService";
import EntityConstants from "../../configs/EntityConstants";
import AuthContext from "../../store/auth-context";

import ArrowLeftIcon from "../../resources/icons/arrow-narrow-left.svg";

import CfLoader from "../../common/CfLoader/CfLoader";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ViewAllDrafts = () => {
  const { maxLimit, currentStartDay, currentEndDay, timestamp } = useParams();

  const [pageName, setPageName] = useState("");

  const authContext = useContext(AuthContext);
  const location = useLocation();

  const [isLodingCredTemplates, setIsLoadingCredTemplates] = useState(false);

  const [offset, setOffset] = useState(0);
  const [prevOffset, setPrevOffset] = useState(0);
  const [limit, setlimit] = useState(8);
  const [startDay, setStartDay] = useState(currentStartDay);
  const [endDay, setEndDay] = useState(currentEndDay);

  const [count, setCount] = useState(0);

  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);

  const [recentList, setRecentList] = useState([]);

  const navigate = useNavigate();

  const SEARCH = () => {
    console.log("search");
  };

  const GET_ISSUER_DRAFTS = (limit, offset) => {
    setIsLoadingCredTemplates(true);
    GET(
      `${EntityConstants.GET_DRAFTS_TEMPLATES}limit=${limit}&offset=${offset}&start_day=${startDay}&end_day=${endDay}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setNext(data.next);
          setPrevious(data.previous);
          setPrevOffset(offset - limit);
          setOffset(offset);
          setCount(data.count);
          setRecentList((perv) => [...perv, ...data.results]);
        }
      })
      .then(() => setIsLoadingCredTemplates(false))
      .catch((error) => {
        console.error(error);
      })
      .then(() => setIsLoadingCredTemplates(false));
  };

  useEffect(() => {
    // if (authContext.hasDiscoveryReachedBottom && !isSearched && contextList.length < count) {
    if (
      authContext.hasDiscoveryReachedBottom &&
      recentList.length < count &&
      prevOffset !== offset + limit
    ) {
      //remaining cards are also covered here ```recentList.length < count``` for the last call
      // The user has reached the bottom of the page
      // You can load more content or trigger some action here
      GET_ISSUER_DRAFTS(limit, offset + limit);
    }
  }, [authContext.hasDiscoveryReachedBottom]);
  // useEffect(() => {
  //   if (authContext.hasDiscoveryReachedBottom && recentList.length < count) {
  //     // Calculate the next offset
  //     const nextOffset = offset + limit;
  //     const remaining = count - recentList.length;
  //     // Check if there are more items to fetch
  //     if (nextOffset < count) {
  //       (remaining < limit && remaining !== 0) ? GET_ISSUER_DRAFTS(limit, nextOffset+remaining): GET_ISSUER_DRAFTS(limit, nextOffset);
  //     }
  //   }
  //   console.log(authContext.hasDiscoveryReachedBottom)
  // }, [authContext.hasDiscoveryReachedBottom, recentList.length, count, offset, limit]);

  useEffect(() => {
    timestamp && setPageName(timestamp.split("_").join(" "));
  }, [timestamp]);

  useEffect(() => {
    if (maxLimit > 0) {
      const MAX_LIMIT = parseInt(maxLimit);
      MAX_LIMIT && GET_ISSUER_DRAFTS(limit, offset);
      console.log("in :) --", maxLimit);
    }
  }, [maxLimit]);
  useEffect(() => {
    console.log("recentList.length", recentList.length);
  }, [recentList.length]);

  useEffect(() => {
    authContext.lastVisitedPathHandler(location.pathname);
  }, []);

  return (
    <div className="flex flex-col items-center w-full mt-20 bg-cfSecondary min-h-screen">
      <div
        className={`w-full bg-cfSearchContainer flex justify-center py-[0.8rem] px-4`}
      >
        <div
          className={`flex flex-col sm:flex-row items-start sm:items-center justify-between w-full`}
        >
          <div className="capitalize flex items-center gap-2 text-cfHeadingText font-semibold text-lg self-center md:self-start my-auto">
            <img
              src={ArrowLeftIcon}
              alt=""
              onClick={() => navigate(-1)}
              className="cursor-pointer"
            />
            <p>{pageName}</p>
          </div>
          {/* Search */}
          <form className=" bg-cfSecondary p-2 rounded-lg w-full sm:w-[25rem] self-center ml-[0.630rem] md:self-end md:m-0">
            <input
              type="text"
              name="search"
              id="search"
              onChange={(e) => SEARCH(e)}
              placeholder="Search in categories"
              className="outline-none border-none p-0 placeholder:text-sm w-full"
              style={{ fontSize: "0.875rem", lineHeight: "1.25rem" }}
            />
          </form>
        </div>
      </div>

      <div className="w-full p-4">
        <div className="flex items-center gap-6 flex-wrap w-full">
          {recentList.map(
            ({
              issuer_credential_template_id,
              credential_template_id,
              certificate_name,
              preview_image,
              created_at,
              updated_at,
              organisation,
              description,
              tagsList,
            }) => (
              <DraftsCredentialTemplateCard
                name={certificate_name}
                credential_template_id={credential_template_id}
                issuer_credential_template_id={issuer_credential_template_id}
                created_at={created_at}
                //! remove this when `updated_at` is added 'lastEdited={created_at?created_at:"14 Jan 2024"}'
                lastEdited={updated_at}
                thumbnail_image={preview_image}
                navigate_url={`/issuer/certificate/0/${credential_template_id}/${issuer_credential_template_id}/edit`}
                description={description}
                tagsList={tagsList}
              />
            )
          )}
          {isLodingCredTemplates && (
            <div className="w-full flex justify-center items-center">
              <CfLoader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewAllDrafts;
