import React from "react";

const SettingOption = ({
  toggleStatus,
  onclickFunction,
  label,
  description,
}) => {
  return (
    <div className="flex xs:mt-5 xs:flex-row">
      <label className="inline-flex items-center cursor-pointer xs:pb-6 md:pb-0">
        <input
          type="checkbox"
          checked={toggleStatus}
          onChange={onclickFunction}
          className="sr-only peer"
        />
        <div className="relative w-11 mb-4 md:mb-12 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer dark:bg-gray-200 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-cfPrimaryLight"></div>
        <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
      </label>
      <div className="flex flex-col sm:mb-5 lg:mb-0 flex-1 text-sm leading-5">
        <div className="font-medium text-slate-700">{label}</div>
        <div className="text-gray-500">{description}</div>
      </div>
    </div>
  );
};

export default SettingOption;
