import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EntityConstants from '../../configs/EntityConstants';
import { GET } from '../../service/CRUDService';
import VisitIcon from "../../resources/icons/external-link.svg";
import TickIcon from "../../resources/icons/shield-tick.svg";
import RevokedIcon from "../../resources/icons/revoked-icon.svg";
import ExpiredIcon from "../../resources/icons/expired-icon.svg";
import toast from 'react-hot-toast';

const CredentialPreview = () => {
  const { cert_hash } = useParams();
  const [certificateData, setCertificateData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCertificateData = async () => {
    try {
      const response = await GET(
        `${EntityConstants.GET_CERTIFICATE_PREVIEW}?encoded_data=${cert_hash}&format=json`
      );
      const responseData = await response.json();
      if (response.ok) {
        setCertificateData(responseData);
      } else {
        console.log(responseData?.error || responseData?.message || responseData?.detail);
        setError('Something went wrong. Please try again!');
        const errorMessage = responseData?.error || responseData?.message || responseData?.detail || 'An unknown error occurred';
        toast.error(errorMessage);
      }
    } catch (err) {
      setError('Something went wrong. Please try again!');
      toast.error(err?.message || 'Failed to load certificate data');
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchCertificateData();
  }, [cert_hash]);

  const handleRedirect = (url) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-white">
        <div className="text-center">
          <div className="spinner-border animate-spin inline-block w-12 h-12 border-4 border-t-4 border-gray-200 rounded-full" role="status"></div>
          <div className="mt-4 text-lg text-gray-600">Loading...</div>
        </div>
      </div>
    );
  }  

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-white">
        <div className="text-red-600 text-center p-4">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="w-full px-4 md:px-6 min-h-screen flex flex-col justify-center font-sans text-gray-900 bg-white pb-6">
      <header className="pb-2 mb-6">
        <img src={certificateData.issuer_logo} alt="Issuer Logo" className="h-10 mb-3 mt-3" />
        <hr className="full-width my-2" />
        <h2 className="text-2xl mt-4">{certificateData.certificate_name}</h2>
      </header>

      <div className="flex flex-col lg:flex-row lg:space-x-6 mb-6 items-stretch">
      {/* Left Section */}
      <div className="w-full lg:w-1/2 p-4 border border-gray-300 rounded-lg relative mb-4 lg:mb-0 flex flex-col justify-between order-2 lg:order-1">
          <div>
            <p className="text-l text-gray-600 mb-2">Certificate issued to</p>
            <h3 className="text-lg font-bold mb-4">{certificateData.recipient_name}</h3>
            <p className="text-l text-gray-600 mb-2">Reference ID on CredIssuer</p>
            <h3 className="text-lg font-bold mb-4">{certificateData.credential_id}</h3>
            <p className="text-l text-gray-600 mb-2">Issuance Date</p>
            <h3 className="text-lg font-bold mb-4">{certificateData.issued_at}</h3>
            <p className="text-l text-gray-600 mb-2">Expiry Date</p>
            <h3 className="text-lg font-bold mb-4">{certificateData.expires_on}</h3>
          </div>

          <div className="flex flex-col justify-center items-center lg:absolute lg:top-1/2 lg:right-4 lg:transform lg:-translate-y-1/2 space-y-4">
            <img src={certificateData.qr_code} alt="QR Code" className="w-48 h-48 mb-4" />
            {certificateData && certificateData.course_url && (
              <button
                className="cursor-pointer py-[0.675rem] px-[3.063rem] font-semibold rounded-lg bg-[#3538CD] text-cfSecondary border border-[#3538CD] focus:outline-none active:bg-[#3538CD] transition duration-200"
                onClick={() => handleRedirect(certificateData.course_url)}
              >
                Get this certificate
              </button>
            )}
          </div>
        </div>
        {/* Right Section */}
        <div className="w-full lg:w-1/2 border border-gray-300 rounded-lg mb-4 lg:mb-0 flex flex-col justify-between order-1 lg:order-2">
          <div className="w-full h-[400px] bg-gray-100 text-center border border-gray-300 rounded-lg overflow-hidden flex items-center justify-center">
            <img
              src={certificateData.svg_string}
              alt="Certificate Preview"
              className="object-contain w-full h-full"
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Certificate Verification */}
        <div className="p-4 border border-gray-300 rounded-lg">
          <h4 className="font-bold mb-4">Certificate Verification</h4>
          <p className="flex items-center mb-2">
            <img src={certificateData.is_revoked ? RevokedIcon : certificateData.is_expired ? ExpiredIcon : TickIcon}
              alt="Status Icon"
              className="h-5 w-5 mr-2"
            />
            This certificate is&nbsp;
            {certificateData.is_revoked ? (
              <span className="font-bold text-red-600">verified, but revoked</span>
              ) : certificateData.is_expired ? (
              <span className="font-bold text-gray-500">expired</span>
              ) : (
              <span className="font-bold text-green-600">verified</span>
            )}
          </p>
          <p className="flex items-center mb-4">
            <img src={TickIcon} alt="Tick" className="h-5 w-5 mr-2" />
            This certificate is from a&nbsp;<span className="font-bold text-green-600">verified issuer</span>
          </p>
          <button
            onClick={() => handleRedirect(certificateData.verify_credential_url)}
            className="mt-4 bg-gray-50 text-gray-900 py-2 px-4 rounded hover:bg-gray-300 transition duration-200 w-full border border-gray-800 flex items-center justify-center space-x-2"
          >
            <span>Verify Certificate</span>
            <img src={VisitIcon} alt="Icon" className="h-4 w-4 text-gray-800" />
          </button>
        </div>

        {/* Issuer Info */}
        <div className="p-4 border border-gray-300 rounded-lg">
          <h4 className="font-bold mb-3">More about the Issuer</h4>
          <div className="flex items-center mb-4 space-x-3">
            <div className="w-12 h-12 rounded-full overflow-hidden border border-gray-300">
              <img
                src={certificateData.issuer_logo}
                alt="Issuer Logo"
                className="w-full h-full object-contain"
              />
            </div>
            <p>{certificateData.org_name}</p>
          </div>

          <button
            onClick={() => handleRedirect(certificateData.issuer_website_url)}
            className="mt-4 bg-gray-50 text-gray-900 py-2 px-4 rounded hover:bg-gray-300 transition duration-200 w-full border border-gray-800 flex items-center justify-center space-x-2"
          >
            <span>Visit Issuer Website</span>
            <img src={VisitIcon} alt="Icon" className="h-4 w-4 text-gray-800" />
          </button>

          {certificateData && certificateData.course_url && (
            <button
              onClick={() => handleRedirect(certificateData.course_url)}
              className="mt-4 bg-gray-50 text-gray-900 py-2 px-4 rounded hover:bg-gray-300 transition duration-200 w-full border border-gray-800 flex items-center justify-center space-x-2"
            >
              <span>Visit Certificate Page</span>
              <img src={VisitIcon} alt="Icon" className="h-4 w-4 text-gray-800" />
            </button>
          )}
        </div>

        {/* Analytics */}
        <div className="p-4 border border-gray-300 rounded-lg flex flex-col justify-center items-center h-full">
          <p className="text-lg text-gray-900 mb-3">This certificate has been issued to</p>
          <p className="text-4xl font-bold text-black mb-3">{certificateData.cert_issued_count}</p>
          <p className="text-lg text-gray-900">individuals over the past year.</p>
        </div>
      </div>
    </div>
  );
};

export default CredentialPreview;