import AppConstants from "../configs/AppConstants";
import EntityConstants from "../configs/EntityConstants";

const API_BASE_URL = AppConstants.HTTPS_HOST;

let isRefreshing = false; // Flag to check if a refresh is in progress
let requestQueue = []; // Queue to hold requests while refreshing the token

//! Handle refresh token after error
const handleRequest = async (url, options) => {
  const response = await fetch(url, options);

  if (response.status === 401) {
    const currentResp = await response.json();

    if (currentResp.code === "token_not_valid") {
      if (!isRefreshing) {
        isRefreshing = true;

        const tokenRefreshed = await handleRefreshToken();

        if (tokenRefreshed) {
          // Retry requests with new token
          requestQueue.forEach(({ url, options, resolve, reject }) => {
            options.headers["authorization"] = `Bearer ${localStorage.getItem(
              "auth_token"
            )}`;
            handleRequest(url, options).then(resolve).catch(reject);
          });
          requestQueue = [];
          isRefreshing = false;

          // Retry the original request with the new token
          options.headers["authorization"] = `Bearer ${localStorage.getItem(
            "auth_token"
          )}`;

          return handleRequest(url, options);
        } else {
          // Token refresh failed; trigger logout
          // const logout = new Logout();
          // logout.handleLogout(); // Call logout function if refresh fails
          return null;
        }
      } else {
        // Queue the request
        return new Promise((resolve, reject) => {
          requestQueue.push({ url, options, resolve, reject });
        });
      }
    }
  }

  if (response.status === 422) {
    const data = await response.json();
    console.log(data);
  }

  return response;
};

// Handle and save refreshed token
const handleRefreshToken = async () => {
  try {
    const response = await POST(`${EntityConstants.GET_REFRESH_ACCESS_TOKEN}`, {
      refresh: localStorage.getItem("refresh_token"),
    });
    // const response = await fetch(`https://mocki.io/v1/4e2f44d1-fac3-4daa-9f25-9b7051494bc8`, {
    //   "refresh": localStorage.getItem("refresh_token"),
    // });
    const data = await response.json();

    if (response.ok && data.access) {
      localStorage.setItem("auth_token", data.access);
      localStorage.setItem("refresh_token", data.refresh);
      return true;
    }
    if (response.status === 404 && data.code === "token_not_valid") {
      localStorage.clear();
    }

    console.log("Token refresh failed.");
    return false;
  } catch (error) {
    console.log(
      "Error occurred in refreshing access token, Please login again",
      error
    );
    console.log("error >>> ", error);
    return false;
  }
};

export const GET = async (url, params = null) => {
  let fullUrl;
  if (params === null) {
    fullUrl = `${API_BASE_URL}${url}`;
  } else {
    fullUrl = `${API_BASE_URL}${url}/?${params}`;
  }
  let options;
  if (localStorage.getItem("auth_token")) {
    options = {
      method: "GET",
      headers: {
        "X-CSRFToken": localStorage.getItem("csrf_token"),
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        "Accept-Language": localStorage.getItem("i18nextLng"),
      },
    };
  } else {
    options = {
      method: "GET",
      headers: {
        "X-CSRFToken": localStorage.getItem("csrf_token"),
        "Accept-Language": localStorage.getItem("i18nextLng"),
      },
    };
  }
  return handleRequest(fullUrl, options);
};

export const POST = async (url, body) => {
  const fullUrl = `${API_BASE_URL}${url}`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": `${localStorage.getItem("csrf_token")}`,
      authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      "Accept-Language": localStorage.getItem("i18nextLng"),
    },
    body: JSON.stringify(body),
  };

  return handleRequest(fullUrl, options);
};

export const PUT = async (url, body) => {
  const fullUrl = `${API_BASE_URL}${url}`;
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": localStorage.getItem("csrf_token"),
      authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      "Accept-Language": localStorage.getItem("i18nextLng"),
    },
    body: JSON.stringify(body),
  };

  return handleRequest(fullUrl, options);
};

export const DEL = async (url) => {
  const fullUrl = `${API_BASE_URL}${url}`;
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": localStorage.getItem("csrf_token"),
      authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      "Accept-Language": localStorage.getItem("i18nextLng"),
    },
  };

  return handleRequest(fullUrl, options);
};

export const POSTFORMDATA = async (url, formData) => {
  const fullUrl = `${API_BASE_URL}${url}`;
  const options = {
    method: "POST",
    headers: {
      "X-CSRFToken": localStorage.getItem("csrf_token"),
      authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      "Accept-Language": localStorage.getItem("i18nextLng"),
    },
    body: formData,
  };

  return handleRequest(fullUrl, options);
};
export const LOGOUT = async (url, body) => {
  const fullUrl = `${API_BASE_URL}${url}`;
  const options = {
    method: "POST",
    headers: {
      "X-CSRFToken": localStorage.getItem("csrf_token"),
      authorization: `Bearer ${localStorage.getItem("refresh_token")}`,
      "Accept-Language": localStorage.getItem("i18nextLng"),
    },
    body: JSON.stringify(body),
  };

  return handleRequest(fullUrl, options);
};

export const GENLINK = (url) => {
  return `${API_BASE_URL}${url}?token=${localStorage.getItem("auth_token")}`;
};
