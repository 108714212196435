import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDeleteSweep } from "react-icons/md";
import { PiCertificate } from "react-icons/pi";
import { Base64 } from "js-base64";

import Copy from "../../../resources/icons/copy.svg";
import Download from "../../../resources/icons/download-01.svg";
import Dots from "../../../resources/icons/dots-horizontal.svg";
import correct from "../../../resources/icons/correct.svg";
import ProfileIcon from "../../../resources/icons/profile-circle.svg";

import CopyToClipBoard from "../../../utils/CopyToClipBoard";
import DownloadFile from "../../../service/DownloadFile";
import EntityConstants from "../../../configs/EntityConstants";
import DownloadRequestModal from "../../../common/DownloadRequestModal/DownloadRequestModal";

const IssuedCredentialLongCard = (props) => {
  const {
    transaction_id,
    name,
    credential_template_id,
    issuer_credential_template_id,
    transaction_status,
    completed_at,
    lastEdited,
    thumbnail_image,
    issued_by,
    userProfileImg,
    receivers,
    issuance_type,
  } = props;

  const [copied, setCopied] = useState(false);
  const [isDownloadRequestModalOpen, setIsDownloadRequestModalOpen] = useState(false);
  const [downloadRequestModalMessage, setDownloadRequestModalMessage] = useState('');
  const [downloadRequestModalHeader, setDownloadRequestModalHeader] = useState('');
  const [isDownloadRequestSuccess, setIsDownloadRequestSuccess] = useState(false);

  const cardOptions = [
    {
      icon: <FiEdit size={23} />,
      title: "Edit",
      func: () =>
        navigate(
          `/issuer/certificate/0/${credential_template_id}/${issuer_credential_template_id}/edit`
        ),
    },
    {
      icon: <PiCertificate size={25} />,
      title: "Issue Certificates",
      func: () =>
        navigate(
          `/issuer/certificate/1/${credential_template_id}/${issuer_credential_template_id}`
        ),
    },
    {
      icon: <MdOutlineDeleteSweep size={25} />,
      title: "Delete",
      func: () => {
        alert("delete");
      },
    },
  ];

  const navigate = useNavigate();

  const handleDownloadZip = async (event, bulk_download) => {
    event.stopPropagation();
    console.log(
      "The EntityConstants.DOWNLOAD_SVG_AS_ZIP, transaction_id : ",
      EntityConstants.DOWNLOAD_SVG_AS_ZIP,
      " ",
      transaction_id
    );
    await DownloadFile(EntityConstants.DOWNLOAD_SVG_AS_ZIP, transaction_id, bulk_download, showMessageModal);
  };

  const showMessageModal = (isSuccess, status, message) => {
    setIsDownloadRequestSuccess(isSuccess)
    setDownloadRequestModalHeader(status)
    setDownloadRequestModalMessage(message);
    setIsDownloadRequestModalOpen(true);
  };

  const handleCopyClick = (event, id) => {
    event.stopPropagation();
    CopyToClipBoard(id);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div
      className="parent-container flex flex-row items-center border rounded-[var(--cf-issued-credential-border-radius)] py-[var(--cf-issued-credential-template-card-padding-y)] px-6 hover:bg-[#F9FAFB]"
      onClick={() =>
        navigate(`/issuer/transaction/${transaction_id}`, {
          state: { transaction_status },
        })
      }
    >
      <div className="main-container1 flex items-center gap-5  w-[45%]">
        <div className="sub-container1 w-1/3 justify-start">
          <p className="text-cfHeadingText font-semibold whitespace-nowrap truncate">
            {name}
          </p>
          <div className="sub-container2 flex text-cfIssuedCredentialTemplateCardDateReceiversText">
            {completed_at && (
              <>
                <p className="whitespace-nowrap">
                  {completed_at}
                  {/* 21 Dec ‘23 */}
                </p>
                &nbsp;|&nbsp;
              </>
            )}
            {receivers > 1 && (
              <p className="whitespace-nowrap">{receivers} Receivers</p>
            )}
            {(receivers === 1 || receivers === 0) && (
              <p className="whitespace-nowrap">{receivers} Receiver</p>
            )}
          </div>
        </div>
        <div className="sub-container1 px-[3rem] 2xl:px-[4rem] ">
          <div className="flex items-center justify-between w-[14.25rem] bg-cfIssuedCredentialTemplateCardDdBG px-3 py-2.5 rounded-[0.25rem]">
            <div>
              <p className="text-cfIssuedCredentialTemplateCardDdText text-cfIssuedCredentialTemplateCardDDText">
                {transaction_id}
              </p>
            </div>
            <div className="relative ml-2">
              {copied ? (
                <img src={correct} alt="" className="w-4 h-4" />
              ) : (
                <img
                  src={Copy}
                  alt=""
                  className="cursor-pointer"
                  onClick={(e) => handleCopyClick(e, transaction_id)}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="main-container2 flex items-center justify-between w-[55%]">
        {/* <div className="sub-container1 flex items-center gap-x-[0.875rem] w-[60%]"> */}
        <div className="flex items-center gap-2">
          <img
            src={userProfileImg ? userProfileImg : ProfileIcon}
            alt=""
            className="rounded-full w-8 h-8"
          />
          <p className="line-clamp-1 w-[11.85rem] border-r border-cfIssuedCredentialTemplateCardStatusSubContainer2Border h-fit">
            {issued_by}
          </p>
        </div>
        <div
          className={`bg-cfIssuedCredentialTemplateCardStatusDoneBG rounded-full  text-cfIssuedCredentialTemplateCardStatusText ${
            transaction_status === "Issued"
              ? "bg-cfIssuedCredentialTemplateCardStatusDoneBG"
              : transaction_status === "Revoked"
              ? "bg-cfIssuedCredentialTemplateCardStatusRevokedBG"
              : transaction_status === "Failed"
              ? "bg-cfIssuedCredentialTemplateCardStatusUnderReviewBG"
              : transaction_status === "Expired"
              ? "bg-cfIssuedCredentialTemplateCardStatusExpiredBG"
              : transaction_status === "Partially Issued"
              ? "bg-cfIssuedCredentialTemplateCardStatusPartiallyIssuedBG"
              : transaction_status === "In Progress"
              ? "bg-cfIssuedCredentialTemplateCardStatusUnderReviewBG"
              : "bg-cfIssuedCredentialTemplateCardStatusText"
          }`}
        >
          <div className="text-sm text-center text-cfIssuedCredentialTemplateCardStatusText px-[0.625rem] py-[0.125rem]">
            {transaction_status}
          </div>
        </div>
        {/* </div> */}
        <div className="sub-container2 flex justify-between items-center gap-x-[0.875rem] w-[34%]">
          <div
            className={`rounded-full border-[1.5px]  ${
              issuance_type === "bulk" &&
              "border-purple-500 text-purple-500 bg-purple-100"
            }
            ${
              issuance_type === "single" &&
              "border-pink-500 text-pink-500 bg-pink-100"
            }`}
          >
            <div className="text-sm text-center px-[0.625rem] py-[0.125rem]">
              {issuance_type}
            </div>
          </div>
          {/* <div className="sub-container2 items-center gap-4 mt-1 "> */}
          <div className="sub-container2 flex items-center gap-4">
            <button
              type="button"
              onClick={(e) => {
                if (receivers !== 0 && receivers >= 1) handleDownloadZip(e, receivers > 1);
              }}
              disabled={receivers === 0}
              className="px-3 py-2 mb-2 border-[1.5px] border-black rounded-full text-[0.875rem]"
            >
              <p className="text-black cursor-pointer">Download</p>
            </button>
          </div>
          <DownloadRequestModal isOpen={isDownloadRequestModalOpen}
                header={downloadRequestModalHeader}
                isSuccess={isDownloadRequestSuccess}
                message={downloadRequestModalMessage}
                onClose={() => setIsDownloadRequestModalOpen(false)} />
        </div>

        {/*<div>
              <img src={Dots} alt="" className="size-6 cursor-pointer" />
            </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default IssuedCredentialLongCard;
