import React from 'react'
import CfLoader from '../CfLoader/CfLoader'

const FullScreenLoader = () => {
  return (
    <div className="absolute top-0 left-0 bg-black/30 w-full h-full flex justify-center items-center z-20">
<CfLoader />
      </div>
  )
}

export default FullScreenLoader