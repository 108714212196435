import React from "react";

const CredentialsDetails = ({
  credentialDescription,
  credentialNumber,
  imageAlt,
  imageSrc,
  backgroundColor,
}) => {
  return (
    <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
      <div className="flex-auto py-4">
        <div className="flex flex-row">
          <div className="px-3 text-right basis-1/4">
            <div
              className={`inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl bg-[${backgroundColor}]`}
              style={{ backgroundColor }}
            >
              <img alt={imageAlt} src={imageSrc} className="px-2 py-2" />
            </div>
          </div>
          <div className="flex-none w-2/3 max-w-full px-3">
            <div>
              <h5 className="mb-2 font-bold dark:text-black">
                {credentialNumber !== null ? credentialNumber : 0}
              </h5>
              <p className="mb-0 dark:text-black dark:opacity-40 text-sm">
                {credentialDescription}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CredentialsDetails;
