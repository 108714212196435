import { BsPersonCircle, BsLockFill } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { CgPassword } from "react-icons/cg";
// import { RiGroup2Fill } from "react-icons/ri";
import { BiShow, BiHide } from "react-icons/bi";
import { MdDateRange } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { GiPerson } from "react-icons/gi";
// Service
import { POST } from "../../service/CRUDService";
// Constants
import EntityConstants from "../../configs/EntityConstants";
// Utils
import { state, schema } from "./SignUpSideCurtains.utils";
// Third Party
import { Form, Formik } from "formik";
import { toast } from "react-hot-toast";
import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import AuthContext from "../../store/auth-context";

import { useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";
import AppConstants from "../../configs/AppConstants";
import { useGoogleLogin } from "@react-oauth/google";
import { GLogin } from "../../service/OAuthLogin";
import MicrosoftIcon from "../../resources/icons/microsoft.svg";
import { microsoftAuth } from "../../service/OAuthLogin";
import GoogleIcon from "../../resources/icons/Google-Social-icon.svg";
import ReCAPTCHA from "react-google-recaptcha";

const SignUpSideCurtains = ({
  toggleComponent,
  closeModal,
  toggleIsMessage,
}) => {
  const { t } = useTranslation();

  const phobe_country_code = require("../../resources/json/phobe_country_code.json");
  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const [isSignOrg, setIsSigOrg] = useState(false);
  const [signUpScheme, setSignUpSchema] = useState(schema);
  const [hideShowpassword, setHideShowpassword] = useState("password");
  const [hideShowConfpassword, setHideShowConfpassword] = useState("password");
  const [captchaValue, setCaptchaValue] = useState(null);

  const InputContainerHeight = "4.375rem";
  const InputBorderRadius = "0.5rem";

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async (values) => {
    try {
      if (!captchaValue) {
        toast.error("Please complete the CAPTCHA.");
        return;
      }
      const user = { user_type: "issuer" };
      values = {
        ...values,
        ...user,
        ...AppConstants.TENANT,
        ...AppConstants.ENTITY,
        captchaToken: captchaValue,
      };

      const response = await POST(EntityConstants.SIGNUPISSUER, values);
      const data = await response.json();

      if (response.ok) {
        toast.success(`User created successfully`);

        setMessage(data.message);
        // Don't close model here; show "message" instead
        // let user sign-in
        // closeModal() //!remove closeModal in the final release if not needed

        return;
      } else if (response.status === 400) {
        toast(
          <div className="flex items-center gap-2">
            <p className="px-2 bg-cfPrimaryLight text-cfSecondary rounded-full">
              !
            </p>
            <div>
              <p className="text-cfPrimaryLight font-semibold">{data?.error}</p>
              <p className="text-cfPrimaryLight">{data?.message}</p>
            </div>
          </div>
        );
      } else if (response.status === 404) {
        toast.error(`${data?.message}`);
      } else {
        toast.error(`${data?.message}`);
      }
    } catch (error) {
      // Handle unexpected errors
      console.error("Signup failed:", error);
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  useEffect(() => {
    message && toggleIsMessage();
  }, [message]);

  const signup = useGoogleLogin({
    onSuccess: async (credentialResponse) => {
      const login = await GLogin(credentialResponse, "success");
      if (login === true) {
        authContext.login() && authContext.googlelogin();
        closeModal();
      }
    },
    onError: (error) => {
      GLogin(error, "failed");
    },
  });

  if (!message && !isSignOrg) {
    return (
      <Formik
        enableReinitialize
        initialValues={state}
        validationSchema={signUpScheme}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ values, touched, errors, handleChange, handleSubmit }) => (
          <Form
            noValidate
            onSubmit={handleSubmit}
            className="flex flex-col justify-center w-full"
          >
            <h3 className="text-4xl font-medium leading-6 text-cfSignInSignUpHeadingText px-8">
              Sign up
            </h3>
            <div className="flex flex-col justify-center items-center text-center w-full overflow-x-hidden overflow-y-auto px-8 py-6">
              <div className="py-3 w-full">
                <p className="text-base text-left text-cfSignUpToYourOrganizationAccountText">
                  Sign up for a personal account
                </p>
              </div>
              <div
                id="inputSignUp"
                className={`flex flex-col gap-[0.625rem] w-full`}
              >
                <div
                  className={`flex flex-col gap-4 rounded-md bg-inherit w-full h-[${InputContainerHeight}]`}
                >
                  <label
                    htmlFor="first_name"
                    className="my-auto font-medium text-sm text-cfSignInSignUpInputLabelText text-left"
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    placeholder={`first name`}
                    className="rounded-md w-full h-12 focus:outline-none border border-cfSignInSignUpInputBorder text-base text-cfSignInSignUpInputPlaceholderText"
                    style={{ borderRadius: InputBorderRadius }}
                    value={values.first_name}
                    onChange={handleChange}
                  />
                </div>
                <p className="text-cfSignInSignUpInputPlaceholderText text-left text-red-600">
                  {errors.first_name &&
                    touched.first_name &&
                    `${errors.first_name}`}
                </p>
                <div
                  className={`flex flex-col gap-4 rounded-md bg-inherit w-full h-[${InputContainerHeight}]`}
                >
                  <label
                    htmlFor="last_name"
                    className="my-auto font-medium text-sm text-cfSignInSignUpInputLabelText text-left"
                  >
                    Last name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    placeholder={`last name`}
                    className="rounded-md w-full h-12 focus:outline-none border border-cfSignInSignUpInputBorder text-base text-cfSignInSignUpInputPlaceholderText"
                    style={{ borderRadius: InputBorderRadius }}
                    value={values.last_name}
                    onChange={handleChange}
                  />
                </div>
                <p className="text-cfSignInSignUpInputPlaceholderText text-left text-red-600">
                  {errors.last_name &&
                    touched.last_name &&
                    `${errors.last_name}`}
                </p>
                <div
                  className={`flex flex-col gap-4 rounded-md bg-inherit w-full h-[${InputContainerHeight}]`}
                >
                  <label
                    htmlFor="email"
                    className="my-auto font-medium text-sm text-cfSignInSignUpInputLabelText text-left"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder={`email`}
                    className="rounded-md w-full h-12 focus:outline-none border border-cfSignInSignUpInputBorder text-base text-cfSignInSignUpInputPlaceholderText"
                    style={{ borderRadius: InputBorderRadius }}
                    value={values.email}
                    onChange={handleChange}
                  />
                </div>
                <p className="text-cfSignInSignUpInputPlaceholderText text-left text-red-600">
                  {errors.email && touched.email && `${errors.email}`}
                </p>
                <div
                  className={`flex flex-col gap-4 rounded-md bg-inherit w-full h-[${InputContainerHeight}]`}
                >
                  <label
                    htmlFor="phone_no"
                    className="my-auto font-medium text-sm text-cfSignInSignUpInputLabelText text-left"
                  >
                    Phone
                  </label>
                  <div className="flex items-center justify-between rounded-md w-full h-11 mr-[.14rem] border border-cfSignInSignUpInputBorder">
                    <div className="flex items-center bg-cfSecondary rounded-l-lg h-10 w-4/12">
                      <select
                        type="country_code"
                        name="country_code"
                        id="country_code"
                        placeholder="country code"
                        className={`border-l-0 border-t-0 border-b-0 border-r border-cfSignInSignUpInputBorder rounded-l-lg cf-text-base h-10 mr-[.14rem] pl-2 focus:outline-none w-full`}
                        value={values.country_code}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        {phobe_country_code.map(({ phone, name, emoji }) => (
                          <option value={phone}>
                            {emoji}
                            {phone}
                          </option>
                        ))}
                      </select>
                    </div>
                    <input
                      type="text"
                      name="phone_no"
                      id="phone_no"
                      placeholder={"Phone number (optional)"}
                      className=" border-none w-full h-10 mr-[.14rem] pl-2 focus:outline-none"
                      style={{ borderRadius: "5px" }}
                      value={values.phone_no}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <p className="text-cfSignInSignUpInputPlaceholderText text-left text-red-600">
                  {errors.phone_no &&
                    touched.phone_no &&
                    `Enter valid phone number`}
                </p>
                <div
                  className={`flex flex-col gap-4 rounded-md bg-inherit w-full h-[${InputContainerHeight}]`}
                >
                  <label
                    htmlFor="password"
                    className="my-auto font-medium text-sm text-cfSignInSignUpInputLabelText text-left"
                  >
                    Password
                  </label>
                  <div className="flex items-center justify-between rounded-md w-full h-11 mr-[.14rem] pl-2 border border-cfSignInSignUpInputBorder">
                    <input
                      type={hideShowpassword}
                      name="password"
                      id="password"
                      placeholder={`password`}
                      className="focus:outline-none text-base text-cfSignInSignUpInputPlaceholderText border-none w-full"
                      style={{ borderRadius: InputBorderRadius }}
                      value={values.password}
                      onChange={handleChange}
                    />
                    <div className="pl-1 pr-2 text-cfSignInSignUpInputPlaceholderText">
                      {hideShowpassword === "password" && (
                        <BiShow
                          onClick={() => {
                            setHideShowpassword("text");
                            if (hideShowConfpassword === "text") {
                              setHideShowConfpassword("password");
                            }
                          }}
                          className="cursor-pointer"
                        />
                      )}
                      {hideShowpassword === "text" && (
                        <BiHide
                          onClick={() => {
                            setHideShowpassword("password");
                          }}
                          className="cursor-pointer"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <p className="text-cfSignInSignUpInputPlaceholderText text-left text-red-600">
                  {errors.password && touched.password && errors.password}
                </p>
                <div
                  className={`flex flex-col gap-4 rounded-md bg-inherit w-full h-[${InputContainerHeight}]`}
                >
                  <label
                    htmlFor="password"
                    className="my-auto font-medium text-sm text-cfSignInSignUpInputLabelText text-left"
                  >
                    Re-enter password
                  </label>
                  <div className="flex items-center justify-between rounded-md w-full h-11 mr-[.14rem] pl-2 border border-cfSignInSignUpInputBorder">
                    <input
                      type={hideShowConfpassword}
                      name="confirm_password"
                      id="confirm_password"
                      placeholder={"Re-enter password"}
                      className="focus:outline-none text-base text-cfSignInSignUpInputPlaceholderText border-none w-full"
                      style={{ borderRadius: InputBorderRadius }}
                      value={values.confirm_password}
                      onChange={handleChange}
                    />
                    <div className="pl-1 pr-2 text-cfSignInSignUpInputPlaceholderText">
                      {hideShowConfpassword === "password" && (
                        <BiShow
                          onClick={() => {
                            setHideShowConfpassword("text");
                            if (hideShowpassword === "text") {
                              setHideShowpassword("password");
                            }
                          }}
                          className="cursor-pointer"
                        />
                      )}
                      {hideShowConfpassword === "text" && (
                        <BiHide
                          onClick={() => setHideShowConfpassword("password")}
                          className="cursor-pointer"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <p className="text-cfSignInSignUpInputPlaceholderText text-left text-red-600">
                  {errors.confirm_password &&
                    touched.confirm_password &&
                    `${errors.confirm_password}`}
                </p>
              </div>
              <div className="w-full my-3 flex flex-col gap-[0.875rem]">
                <div>
                  <input
                    type="submit"
                    value={t`Sign up`}
                    className="rounded-xl text-base font-semibold bg-cfButton hover:bg-cfPrimaryDark py-1 px-5 text-cfSecondary w-full h-11"
                  />
                </div>
                <div className="flex custom-recaptcha-width justify-center items-center">
                  <ReCAPTCHA
                    sitekey={AppConstants.GOOGLE_CAPTCHA_SITE_KEY}
                    onChange={handleCaptchaChange}
                  />
                </div>
                <div className="flex items-center w-full gap-2.5 text-cfSignInSignUpOrText">
                  <hr className="w-1/2" />
                  <p>or</p>
                  <hr className="w-1/2" />
                </div>
                <div className="flex gap-4">
                  <button
                    onClick={signup}
                    className="flex items-center gap-2 justify-center w-full h-11 border rounded-full"
                  >
                    <img src={GoogleIcon} alt="google" />
                    <p className="font-semibold text-base font-CfPrimaryFont ">
                      Sign up
                    </p>
                  </button>
                  <button
                    type="button"
                    onClick={microsoftAuth}
                    className="flex items-center gap-2 justify-center w-full h-11 border rounded-full"
                  >
                    <img src={MicrosoftIcon} alt="google" />
                    <p className="font-semibold text-base font-CfPrimaryFont ">
                      Sign up
                    </p>
                  </button>
                </div>
              </div>
              {/* <div className="text-[1rem] leading-[1.2rem] mb-2">
                <button onClick={toggleComponent} className="flex">
                  {t("signUp.have_an_existing_account")} &nbsp;
                  <p className="text-cfPrimary">{t("signUp.sign_in")}</p>
                </button>
              </div> */}
            </div>
            {/* <div className="bg-cfSignUpBottomSection h-full w-full flex flex-col items-center gap-3 px-8 py-6 rounded-b-[1.25rem]">
              <p className="text-cfSignUpAreYouAnAdministratorText text-sm">
                Are you an admin?
              </p>
              <button
                onClick={signup}
                className="flex items-center gap-2 justify-center w-full h-11 border rounded-xl bg-cfSecondary"
              >
                Create your organization
              </button>
            </div> */}
          </Form>
        )}
      </Formik>
    );
  } else if (message && !isSignOrg) {
    return (
      <div className="w-full px-8 py-6">
        <div className={`bg-sky-200 rounded-md p-2 text-center  shadow-md`}>
          {message}
        </div>
        <div className="flex justify-center text-[1rem] leading-[1.2rem] mb-2">
          <button
            onClick={toggleComponent}
            className="flex m-3 bg-cfPrimaryLight hover:bg-cfPrimaryLight rounded-lg text-cfSecondary px-2 py-1"
          >
            <p>Sign in</p>
          </button>
        </div>
      </div>
    );
  } else if (!message && isSignOrg) {
    /*Orginasation Registration Flow*/
  }
};

export default SignUpSideCurtains;
