import React, { useContext, useEffect, useState } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import Template from "../../resources/icons/brush.svg";
import Issued from "../../resources/icons/security-safe.svg";
import Draft from "../../resources/icons/folder-favorite.svg";
import Settings from "../../resources/icons/setting-4.svg";
// import TemplateDisabled from "../../resources/icons/brushDisabled.svg";
import IssuedDisabled from "../../resources/icons/security-safeDisabled.svg";
import DraftDisabled from "../../resources/icons/folder-favoriteDisabled.svg";
import SettingsDisabled from "../../resources/icons/setting-4Disabled.svg";
import HomeIcon from "../../resources/icons/home-icon.svg";

const Sidebar = () => {
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [home, setHome] = useState("/");

  useEffect(() => {
    if (authContext.isLoggedIn) {
      setHome("/issuer/discover");
    }
  }, [authContext.isLoggedIn]);

  const isOrganizationPage = location.pathname.startsWith("/organizations");
  if (isOrganizationPage && !authContext.isLoggedIn) {
    return null;
  }

  const handleIssuedClick = (e) => {
    e.preventDefault();
    authContext.IssuedOffsetHandler(0);
    localStorage.setItem("transactionOffset", 0); // Reset the offset to 0
    navigate("/issuer/transaction/all");
  };

  const public_navs = [
    authContext.isLoggedIn
      ? {
          name: "Dashboard",
          to: "/",
          match: {
            type: "exact",
            path: "/",
          },
          icon: HomeIcon,
        }
      : {
          name: "Templates",
          to: "/discovery",
          match: {
            type: "exact",
            path: "/discovery",
          },
          icon: Template,
        },
  ];
  const issuer_navs = [
    {
      name: "Templates",
      to: "/discovery",
      match: {
        type: "exact",
        path: "/discovery",
      },
      icon: Template,
    },
    {
      name: "Drafts",
      to: "/issuer/drafts",
      match: {
        type: "partial",
        path: "/issuer/drafts",
      },
      icon: Draft,
      disabledIcons: DraftDisabled,
    },
    {
      name: "Issued",
      to: "/issuer/transaction/all",
      match: {
        type: "partial",
        path: "/issuer/transaction/",
      },
      icon: Issued,
      disabledIcons: IssuedDisabled,
      onClick: handleIssuedClick,
    },
    {
      name: "Settings",
      to: "/issuer/settings",
      match: {
        type: "partial",
        path: "/issuer/settings",
      },
      icon: Settings,
      disabledIcons: SettingsDisabled,
    },
  ];

  return (
    <div className="flex flex-col gap-3 h-full bg-cfSecondary overflow-y-auto items-center py-3  w-[5.688rem] border-r-[1.5px]">
      <div className="w-full justify-between">
        <div className=" w-full flex flex-col font-CfSecondaryFont gap-y-4">
          {public_navs.map(({ name, to, match, icon }, index) => (
            <NavLink
              key={name}
              to={to}
              title={name}
              className={`flex w-full font-CfPrimaryFont text-sm capitalize py-2 bg-opacity-40 hover:text-cfPrimaryLight hover:no-underline`}
            >
              <div
                className={`h-14 w-1 absolute left-0  ${
                  ((match.type === "exact" &&
                    location.pathname === match.path) ||
                    (match.type === "partial" &&
                      location.pathname.startsWith(match.path))) &&
                  "bg-cfPrimaryCardHover"
                }`}
              />
              <div
                className={`w-full flex flex-col items-center gap-3 justify-center ${
                  ((match.type === "exact" &&
                    location.pathname === match.path) ||
                    (match.type === "partial" &&
                      location.pathname.startsWith(match.path))) &&
                  "font-semibold"
                }`}
              >
                <img src={icon} alt="" className="w-5" />
                {name}
              </div>
            </NavLink>
          ))}
          {authContext.isLoggedIn &&
            issuer_navs.map(({ name, to, match, icon, onClick }, index) => (
              <>
                {!(index === issuer_navs.length - 1) && (
                  <NavLink
                    key={name}
                    to={to}
                    title={name}
                    className={`flex w-full font-CfPrimaryFont text-sm capitalize py-2 bg-opacity-40 hover:text-cfPrimaryLight hover:no-underline`}
                    onClick={onClick}
                  >
                    <div
                      className={`h-14 w-1 absolute left-0  ${
                        ((match.type === "exact" &&
                          location.pathname === match.path) ||
                          (match.type === "partial" &&
                            location.pathname.startsWith(match.path))) &&
                        "bg-cfPrimaryCardHover"
                      }`}
                    />
                    <div
                      className={`w-full flex flex-col items-center gap-3 justify-center ${
                        ((match.type === "exact" &&
                          location.pathname === match.path) ||
                          (match.type === "partial" &&
                            location.pathname.startsWith(match.path))) &&
                        "font-semibold"
                      }`}
                    >
                      <img src={icon} alt="" className="w-6" />
                      {name}
                    </div>
                  </NavLink>
                )}
              </>
            ))}
        </div>
      </div>
      {authContext.isLoggedIn ? (
        <div className="h-xs:absolute bottom-20">
          <NavLink
            to={issuer_navs[issuer_navs.length - 1].to}
            title={issuer_navs[issuer_navs.length - 1].name}
            className={`flex w-full font-CfPrimaryFont text-sm capitalize py-2 bg-opacity-40 hover:text-cfPrimaryLight hover:no-underline`}
          >
            <div
              className={`h-14 w-[0.360rem] absolute -left-4 ${
                ((issuer_navs[issuer_navs.length - 1].match.type === "exact" &&
                  location.pathname ===
                    issuer_navs[issuer_navs.length - 1].match.path) ||
                  (issuer_navs[issuer_navs.length - 1].match.type ===
                    "partial" &&
                    location.pathname.startsWith(
                      issuer_navs[issuer_navs.length - 1].match.path
                    ))) &&
                "bg-cfPrimaryCardHover"
              }`}
            />
            <div
              className={`w-full flex flex-col items-center justify-center  ${
                ((issuer_navs[issuer_navs.length - 1].match.type === "exact" &&
                  location.pathname ===
                    issuer_navs[issuer_navs.length - 1].match.path) ||
                  (issuer_navs[issuer_navs.length - 1].match.type ===
                    "partial" &&
                    location.pathname.startsWith(
                      issuer_navs[issuer_navs.length - 1].match.path
                    ))) &&
                "font-semibold"
              }`}
            >
              <img
                src={issuer_navs[issuer_navs.length - 1].icon}
                alt=""
                className="w-6"
              />
              {issuer_navs[issuer_navs.length - 1].name}
            </div>
          </NavLink>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Sidebar;
