import React from 'react';
import '../resources/css/JsonPrettifier.css'; // Assuming you put the styles in this CSS file

// Prettify JSON function
function PrettyPrintJSON(json) {
  let parsedJson;
  try {
    parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
  } catch (error) {
    return `<div class="__json-pretty-error">Invalid JSON: ${error.message}</div>`;
  }

  return `<pre class="__json-pretty">${syntaxHighlight(parsedJson)}</pre>`;
}

// Syntax Highlighting function
function syntaxHighlight(json) {
  if (typeof json !== 'string') {
    json = JSON.stringify(json, undefined, 2);
  }

  json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  return json.replace(
    /("(\\u[\da-fA-F]{4}|\\[^u]|[^\\"])*"(\s*:)?)|(\b(true|false|null)\b)|(\b[0-9]+\b)/g,
    function (match) {
      let cls = '__json-string';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = '__json-key';
        }
      } else if (/true|false/.test(match)) {
        cls = '__json-boolean';
      } else if (/null/.test(match)) {
        cls = '__json-null';
      } else {
        cls = '__json-value';
      }
      return `<span class="${cls}">${match}</span>`;
    }
  );
}

// React component
const JsonPrettifier = ({ jsonData }) => {
  const jsonHtml = PrettyPrintJSON(jsonData);
  return (
    <div
      className="json-container"
      dangerouslySetInnerHTML={{ __html: jsonHtml }}
    />
  );
};

export default JsonPrettifier;
