import React, { useEffect, useState } from "react";
import Debounce from "../service/Debounce";

function usePageBottom(options, targetElement) {
  const [reachedBottom, setReachedBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollHeight, clientHeight, scrollTop } = targetElement.current || document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight) {
        setReachedBottom(true);
      } else {
        setReachedBottom(false);
      }
    };

    const handleResize = Debounce({
      func: () => {
        handleScroll();
      },
      delay: 200,
    });

    const eventTarget = targetElement.current || window;

    eventTarget.addEventListener('scroll', handleScroll, options);
    eventTarget.addEventListener('resize', handleResize);

    return () => {
      eventTarget.removeEventListener('scroll', handleScroll);
      eventTarget.removeEventListener('resize', handleResize);
    };
  }, [options, targetElement]);

  return reachedBottom;
}

export default usePageBottom;
