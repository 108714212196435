import React, { useContext, useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import { Outlet, useLocation } from "react-router-dom";
import LoginModal from "../common/LoginModal/LoginModal";
import Sidebar from "../components/Sidebar/Sidebar";
import usePageBottom from "../hooks/usePageBottom";
import AuthContext from "../store/auth-context";

const BaseLayout = () => {
  const authContext = useContext(AuthContext);
  const customElementRef = useRef(null);
  const reachedBottom = usePageBottom(
    { rootMargin: 0, threshold: 0.5 },
    customElementRef
  );
  const [signOrSignUp, setSignOrSignUp] = useState(true);
  const [triggerLoginModuleToggleValue, setTriggerLoginModuleToggleValue] =
    useState(false);

  const location = useLocation();
  const isOrganizationPage = location.pathname.includes("/organizations");
  const shouldShowSidebar = authContext.isLoggedIn || !isOrganizationPage;

  const TriggerLoginModuleBtnFromModule = () => {
    setTriggerLoginModuleToggleValue(false);
  };
  const TriggerLoginModuleBtn = () => {
    setTriggerLoginModuleToggleValue(true);
  };
  const chooseSignUp_SignIn = (bool) => {
    setSignOrSignUp(bool);
  };
  useEffect(() => {
    authContext.hasDiscoveryReachedBottomHandler(reachedBottom);
  }, [reachedBottom]);
  return (
    <React.Fragment>
      <div className="fixed top-0 w-full z-10">
        <Navbar
          TriggerLoginModuleBtn={TriggerLoginModuleBtn}
          chooseSignUp_SignIn={chooseSignUp_SignIn}
        />
      </div>
      <div className="overflow-y-hidden">
        {shouldShowSidebar && (
          <aside
            className="fixed top-[5.1rem] left-0 w-fit h-screen z-10"
            aria-label="Sidebar"
          >
            <div className="h-full overflow-y-auto">
              <Sidebar />
            </div>
          </aside>
        )}
        <div
          className={`${
            shouldShowSidebar ? "ml-[5.7rem]" : "ml-0"
          } mt-[5.1rem] overflow-auto`}
        >
          <Outlet />
        </div>
      </div>
      <LoginModal
        TriggerBtn={triggerLoginModuleToggleValue}
        TriggerModuleBtnFromModule={TriggerLoginModuleBtnFromModule}
        signOrSignUp={signOrSignUp}
        chooseSignUp_SignIn={chooseSignUp_SignIn}
        DisplayText=""
      />
    </React.Fragment>
  );
};

export default BaseLayout;
