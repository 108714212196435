import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import CrossIcon from "../../resources/icons/x-close.svg";
import CfLoader from "../CfLoader/CfLoader";
import RedirectPage from "../../resources/icons/redirect.svg";
import DisabledRedirectPage from "../../resources/icons/disabled-redirect.svg";

const CredentialPreviewModal = (props) => {
  const ImageLoading = (
    <svg width="32rem" height="18.875rem" xmlns="http://www.w3.org/2000/svg">
      <rect width="100%" height="100%" fill="#f0f0f0" />
      <foreignObject
        x="91%"
        y="91%"
        width="200"
        height="200"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div xmlns="http://www.w3.org/1999/xhtml">
          <CfLoader />
        </div>
      </foreignObject>
    </svg>
  );
  const {
    credential_id,
    approved_by,
    issuer_name,
    email_id,
    mobile_number,
    recipient_name,
    status,
    updated_at,
    svg_url,
    thumbnail_url,
    whatsapp_number,
    openCloseModal,
    isOpen,
    org_name,
    public_verify_url,
  } = props;
  const [currentImg, setCurrentImg] = useState(null);
  const [imageLoadError, setImageLoadError] = useState(false);
  useEffect(() => {
    if (svg_url) {
      fetch(svg_url)
        .then((res) => {
          if (!res.ok) {
            throw new Error("Network response was not ok");
          }
          return res.blob();
        })
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          setCurrentImg(url);
          setImageLoadError(false);
        })
        .catch((error) => {
          console.error(error);
          setImageLoadError(true);
        });
    } else {
      setCurrentImg();
      setImageLoadError(true);
    }
  }, [svg_url]);
  console.log("currentImg", currentImg);
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => openCloseModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 top-20 bg-black/30  backdrop-blur-sm " />
        </Transition.Child>

        <div className="fixed inset-0 top-20 overflow-y-auto px-4 md:px-0 max-h-full">
          <div className="flex items-center min-h-full justify-center text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg max-h-[85vh] transform overflow-x-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all overflow-y-auto">
                <div className="flex items-center justify-between py-[1.313rem] px-8 font-medium">
                  <p className="flex flex-col sm:flex-row">
                    Certificate preview {credential_id && "-"}{" "}
                    <div className="flex">
                      {credential_id ? (
                        <div className="ml-[0.3rem]">{credential_id}</div>
                      ) : (
                        "Unavailable"
                      )}
                      {public_verify_url ? (
                        <div className="ml-[0.8rem]">
                          <img
                            src={RedirectPage}
                            alt="RedirectPage"
                            className="w-[1.3rem] cursor-pointer"
                            onClick={() =>
                              window.open(public_verify_url, "_blank")
                            }
                            title="Verification Page"
                          />
                        </div>
                      ) : (
                        <div className="ml-[0.8rem]">
                          <img
                            src={DisabledRedirectPage}
                            alt=""
                            className="w-[1.3rem] cursor-not-allowed"
                          />
                        </div>
                      )}
                    </div>
                  </p>
                  <div>
                    <img
                      src={CrossIcon}
                      alt=""
                      onClick={() => openCloseModal(false)}
                      className="w-[1.3rem] cursor-pointer"
                    />
                  </div>
                </div>
                <div>
                  <div className="w-full bg-[#F2F4F7]">
                    {currentImg && !imageLoadError ? (
                      <img
                        src={currentImg}
                        alt=""
                        className="w-full px-12 py-[0.875rem]"
                      />
                    ) : (
                      ImageLoading
                    )}
                  </div>
                  <div className="p-6">
                    <div>
                      <div className="text-[#344054] font-bold text-[0.875rem] pb-2">
                        <p>Certificate Details</p>
                      </div>
                      <div className="text-[#242F46] pb-3">
                        <span className="flex items-center text-[0.875rem] pb-[1rem]">
                          <p>Name:</p>&nbsp;
                          <p>
                            {recipient_name ? recipient_name : "Unavailable"}
                          </p>
                        </span>
                        <span className="flex items-center text-[0.875rem] pb-[1rem]">
                          <p>Email id:</p>&nbsp;
                          <p>{email_id ? email_id : "Unavailable"}</p>
                        </span>
                        <span className="flex items-center text-[0.875rem] pb-[1rem]">
                          <p>Phone number:</p>&nbsp;
                          <p>{mobile_number ? mobile_number : "Unavailable"}</p>
                        </span>
                        <span className="flex items-center text-[0.875rem] pb-[1rem]">
                          <p>WhatsApp number:</p>&nbsp;
                          <p>
                            {whatsapp_number ? whatsapp_number : "Unavailable"}
                          </p>
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div>
                      <div className="flex items-center justify-between pb-2 pt-3">
                        <div className="text-[#344054] font-bold text-[0.875rem]">
                          <p>Issuance Details</p>
                        </div>
                        <div className="flex items-center gap-4">
                          <p className="text-[#344054] font-bold text-[0.92rem]">
                            Status
                          </p>
                          <p
                            className={`px-[1.125rem] py-[0.125rem] text-cfSecondary rounded-full ${
                              status === "Issued"
                                ? "bg-cfIssuedCredentialTemplateCardStatusDoneBG"
                                : status === "Failed"
                                ? "bg-cfIssuedCredentialTemplateCardStatusUnderReviewBG"
                                : status === "Revoked"
                                ? "bg-cfIssuedCredentialTemplateCardStatusRevokedBG"
                                : !status && " bg-gray-300 "
                            }`}
                          >
                            {status ? status : "Unavailable"}
                          </p>
                        </div>
                      </div>
                      <div className="text-[#242F46] mt-[0.4rem] ">
                        <span className="flex items-center text-[0.875rem] pb-[1rem]">
                          <p>Issued by:</p>&nbsp;
                          <p>{issuer_name ? issuer_name : "Unavailable"}</p>
                          {org_name && (
                            <p>
                              ,&nbsp;
                              {org_name ? org_name : "Unavailable"}
                            </p>
                          )}
                        </span>
                        <span className="flex items-center text-[0.875rem] pb-[1rem]">
                          <p>Issuance date:</p>&nbsp;
                          <p>{updated_at ? updated_at : "Unavailable"}</p>
                        </span>
                      </div>
                    </div>
                    {/* <hr />
                      <div>
                       <div>
                        <span>
                          <img src={TimestampClockIcon} alt="" />
                        </span>
                        <div>
                          <p>Certificate re-issued via email and whatsapp</p>
                          <p>19 March 2024  | 10:40 AM</p>
                        </div>
                       </div>
                       <hr />
                      </div> */}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CredentialPreviewModal;
