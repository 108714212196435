import React from "react";
import { useNavigate } from "react-router-dom";
import HandleImgError from "../../../utils/HandleURLImgError";

const CredentialTemplateCard = (props) => {
  const {
    name,
    description,
    credential_template_id,
    tagsList,
    thumbnail_image,
    navigate_url,
  } = props;
  const navigate = useNavigate();
  return (
    <div
      className={`group flex flex-col gap-3 bg-cfSecondary border border-cfCardBorder ${
        credential_template_id &&
        "cursor-pointer hover:bg-cfCardHover hover:scale-105"
      } transition-all duration-500 rounded-2xl overflow-hidden w-[19.125rem]`}
      name={credential_template_id}
      onClick={() => {
        (credential_template_id && navigate_url) &&
          navigate(navigate_url);
      }}
    >
      <div className="flex flex-col h-full">
        <div className="flex flex-col h-fit">
          <div
            className={`flex items-center h-44 border-b w-full bg-cfSecondary ${
              !thumbnail_image && "w-full animate-pulse bg-zinc-300"
            }`}
          >
            <img
              src={(thumbnail_image || thumbnail_image === null) && thumbnail_image}
              onError={(e)=> HandleImgError(e,thumbnail_image)}
              alt=""
              className="w-full h-full object-cover object-top"
            />
          </div>
        </div>

        <div className=" h-36 py-2.5 px-3">
          <div className="h-10  overflow-ellipsis font-medium text-lg">
            <p
              className={`line-clamp-1 ${
                !name && "h-8 w-full animate-pulse bg-zinc-300 rounded-md"
              }`}
            >
              {name}
            </p>
          </div>
          <div className={`h-10 `}>
            <p
              className={`text-xs line-clamp-2 ${
                !description &&
                "h-8 w-full animate-pulse bg-zinc-300 text-gray-400 rounded-md"
              }`}
            >
              {description}
            </p>
          </div>
          <div className="flex gap-1 my-2 h-8">
            {tagsList ? (
              tagsList.slice(0, 2).map((item, index) => (
                <p
                  key={index}
                  className=" text-xs bg-cfCardTagFill text-cfCardTagText font-medium group-hover:bg-cfTagFillHover group-hover:text-cfSecondary w-fit px-2 py-1 rounded-full h-fit"
                >
                  {item}
                </p>
              ))
            ) : (
              <>
                <p className="h-5 w-4/12 animate-pulse text-xs bg-zinc-300 px-2 py-1 rounded-full"></p>
                <p className="h-5 w-5/12 animate-pulse text-xs bg-zinc-300 px-2 py-1 rounded-full"></p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CredentialTemplateCard;
