import React from 'react';
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import CrossIcon from "../../resources/icons/x-close.svg";
import CfLoader from "../CfLoader/CfLoader";
import { GET } from '../../service/CRUDService';
import EntityConstants from '../../configs/EntityConstants';
import JsonPrettifier from '../../service/PrettyPrintJSON';
import CopyToClipBoard from '../../utils/CopyToClipBoard';
import  Copy from "../../resources/icons/copy.svg";
import Correct from "../../resources/icons/correct.svg";


const JsonPreviewModal = (props) => {
    const [copied, setCopied] = useState(false);

    const handleCopyClick = (event, id) => {
        event.stopPropagation();
        CopyToClipBoard(id);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      };

    const ImageLoading = (
        <svg width="32rem" height="18.875rem" xmlns="http://www.w3.org/2000/svg">
          <rect width="100%" height="100%" fill="#f0f0f0" />
          <foreignObject
            x="91%"
            y="91%"
            width="200"
            height="200"
            style={{ transform: "translate(-50%, -50%)" }}
          >
            <div xmlns="http://www.w3.org/1999/xhtml">
              <CfLoader />
            </div>
          </foreignObject>
        </svg>
      );
      const {
        credential_id,
        openCloseModal,
        isOpen,
      } = props;

      const [imageLoadError, setImageLoadError] = useState(false);
      const [json,setJson] = useState({});

      useEffect(()=>{
        GET(`${EntityConstants.GET_CREDENTIAL_JSON}/${credential_id}/json`).then((resp)=>{
            return resp.json()
        }).then((data)=>{
            setJson(data);
        }).catch((err)=>{
            console.log("error in fetching JSON >>> ", err)
        })
      },[credential_id])

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => openCloseModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 top-20 bg-black/30  backdrop-blur-sm " />
        </Transition.Child>

        <div className="fixed inset-0 top-20 overflow-y-auto px-4 md:px-0 max-h-full">
          <div className="flex items-center min-h-full justify-center text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl  max-h-[90vh] transform overflow-x-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all overflow-y-auto">
                <div className="flex items-center justify-between py-[1.313rem] px-8 font-medium">
                  <p className="flex flex-col sm:flex-row">
                    {credential_id}
                  </p>
                  <div>
                    <img
                      src={CrossIcon}
                      alt=""
                      onClick={() => openCloseModal(false)}
                      className="w-[1.3rem] cursor-pointer"
                    />
                  </div>
                </div>
                <div className='bg-[#F2F4F7] p-4'>
                    {Object.values(json).length > 0 ? (
                        <>
                        <div className='w-full bg-cfSecondary flex items-center justify-end p-2'>
                        {copied ? (
                <img src={Correct} alt="" className="w-4 h-4" />
              ) : (
                <img
                  src={Copy}
                  alt=""
                  className="cursor-pointer w-6 h-6 "
                  onClick={(e) => handleCopyClick(e, JSON.stringify(json))}
                />
              )}
                        </div>
                       <JsonPrettifier jsonData={json} />
                       </>
                    ) : (
                      ImageLoading
                    )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default JsonPreviewModal