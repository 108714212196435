import React, { useState, useEffect, useRef } from "react";
import DownArrow from "../../resources/icons/chevron-down.svg";

const Dropdown = ({ options, selected: initialSelected, onSelect, buttonClass, menuClass }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(initialSelected);
  const dropdownRef = useRef(null);

  useEffect(() => {
    onSelect(selected.value); // Trigger update function on initial render
  }, [selected.value, onSelect]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleOptionClick = (option) => {
    setSelected(option);
    setIsOpen(false);
    onSelect(option.value);
  };

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div>
        <button
          onClick={toggleDropdown}
          className={`inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-cfDropdownSelectedText focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 border ${buttonClass}`}
        >
          {selected.name} {/* Display the name of the selected option */}
          <img src={DownArrow} alt="" className="ml-2" />
        </button>
      </div>
      {isOpen && (
        <div
          className={`absolute left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none transition ease-out duration-100 transform opacity-100 scale-100 ${menuClass}`}
        >
          {options.map((option) => (
            <button
              key={option.value}
              onClick={() => handleOptionClick(option)}
              className={`${
                selected.value === option.value
                  ? "bg-violet-500 text-white"
                  : "text-cfDropdownOptionsText hover:bg-gray-200"
              } group flex w-full items-center first:rounded-t-md last:rounded-b-md px-2 py-2 text-sm transition-colors duration-150`}
            >
              {option.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
