import React, { useEffect, useContext, useState, useRef } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import EntityConstants from "../../configs/EntityConstants";
import { GET } from "../../service/CRUDService";
import AuthContext from "../../store/auth-context";
import CalculateCardsPerRow from "../../service/CalculateCardsPerRow";
import TopRated from "../../resources/icons/Top_rated.svg";
import UserImage from "../../resources/icons/Users.svg";
import PlayImage from "../../resources/icons/PlayCircle.svg";
import GlobeImage from "../../resources/icons/Globe.svg";
import FacebookLogo from "../../resources/icons/FacebookLogo.svg";
import TwitterLogo from "../../resources/icons/twitter-x.svg";
import InstagramLogo from "../../resources/icons/InstagramLogo.svg";
import YoutubeLogo from "../../resources/icons/YouTubeLogo.svg";
import WhatsAppLogo from "../../resources/icons/WhatsAppLogo.svg";
import LinkedInLogo from "../../resources/icons/linkedin-outline.svg";
import OrganizationProfileBanner from "../../components/Cards/OrganizationProfileBanner/OrganizationProfileBanner";
import { useParams } from "react-router-dom";

function OrganizationPage() {
  const { organizationCode } = useParams();
  const authContext = useContext(AuthContext);

  const [cardWidth, setCardWidth] = useState("19.125rem");
  const [gap, setGap] = useState("1.5rem");
  const containerRef = useRef(null);
  const [cardsPerRow, setCardsPerRow] = useState(0);
  const [isSearched, setIsSearched] = useState(false);
  const [respContentList, setRespContentList] = useState([]);
  const [contextList, setContextList] = useState([]);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setlimit] = useState(6);
  const [orgLogo, setOrgLogo] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgAbout, setOrgAbout] = useState("");
  const [orgStudents, setOrgStudents] = useState("");
  const [orgCourses, setOrgCourses] = useState("");
  const [orgWebUrl, setOrgWebUrl] = useState("");
  const [orgFacebookUrl, setOrgFacebookUrl] = useState("");
  const [orgTwitterUrl, setOrgTwitterUrl] = useState("");
  const [orgInstagramUrl, setOrgInstagramUrl] = useState("");
  const [orgYoutubeUrl, setOrgYoutubeUrl] = useState("");
  const [orgWhatsAppUrl, setOrgWhatsAppUrl] = useState("");
  const [orgLinkedinUrl, setOrgLinkedinUrl] = useState("");
  const [orgDescription, setOrgDescription] = useState("");
  const [isLodingCredTemplates, setIsLoadingCredTemplates] = useState(false);
  const [hasFetchedCredentials, setHasFetchedCredentials] = useState(false); // New state variable

  const GET_ORG_DETAILS = async () => {
    await GET(
      `${EntityConstants.GET_ORGANIZATION_DETAILS}${organizationCode}/info`
    )
      .then((response) => response.json())
      .then((data) => {
        setOrgLogo(data.logo);
        setOrgName(data.name);
        setOrgAbout(data.about);
        setOrgStudents(data.members_count);
        setOrgCourses(data.offerings_count);
        setOrgWebUrl(data.web_url);
        setOrgFacebookUrl(data.facebook_url);
        setOrgTwitterUrl(data.twitter_url);
        setOrgInstagramUrl(data.instagram_url);
        setOrgYoutubeUrl(data.youtube_url);
        setOrgWhatsAppUrl(data.whatsapp_url);
        setOrgLinkedinUrl(data.linkedin_url);
        setOrgDescription(data.description);
      });
  };

  useEffect(() => {
    GET_ORG_DETAILS();
  }, []);

  const GET_TEMPLATE_LIST = async (limit, offset, cardsPerRow) => {
    setIsLoadingCredTemplates(true);
    await GET(
      `${EntityConstants.GET_ORGANIZATION_CERTIFICATES}limit=${limit}&offset=${offset}&org_code=${organizationCode}`
    )
      .then((response) => response.json())
      .then((data) => {
        setCount(data.count);
        setOffset(offset);
        const hasCredentials = data.results.some(
          (item) =>
            Array.isArray(item.credentials) && item.credentials.length > 0
        );

        if (hasCredentials) {
          setHasFetchedCredentials(true);
        } else if (!hasFetchedCredentials) {
          setIsSearched(true);
        }

        setRespContentList([...respContentList, ...data.results]);
      })
      .then(() => setIsLoadingCredTemplates(false))
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const updateCardsPerRow = () => {
      if (containerRef.current) {
        const calculatedCardsPerRow = CalculateCardsPerRow(
          containerRef,
          cardWidth,
          gap
        );
        if (calculatedCardsPerRow < 5) {
          setCardsPerRow(4);
        } else {
          setCardsPerRow(calculatedCardsPerRow);
        }
      }
    };

    updateCardsPerRow();
  }, [cardWidth, gap]);

  useEffect(() => {
    if (respContentList) {
      setContextList([...respContentList]);
    }
  }, [respContentList]);

  useEffect(() => {
    if (
      authContext.hasDiscoveryReachedBottom &&
      !isSearched &&
      contextList.length < count &&
      cardsPerRow > 0
    ) {
      GET_TEMPLATE_LIST(limit, offset + limit, cardsPerRow);
    }
  }, [authContext.hasDiscoveryReachedBottom]);

  useEffect(() => {
    if (cardsPerRow > 0) {
      GET_TEMPLATE_LIST(limit, offset, cardsPerRow);
    }
    sessionStorage.setItem("workflow_id", null);
  }, [cardsPerRow]);

  return (
    <div>
      <div className="h-full ">
        <div className="bxm font-CfPrimaryFont bg-cfSecondary min-h-screen">
          <div className="flex flex-col items-center w-full mb-4 ">
            <div className="flex flex-col gap-y-4 p-4 w-full">
              <div className="flex flex-col lg:flex-row justify-between items-center border border-[#FFDDD1] p-[2.5rem]">
                <div className="flex flex-col md:flex-row gap-5 items-center">
                  <div
                    className={`${
                      orgWebUrl ? "hover:cursor-pointer" : "cursor-default"
                    } w-[10rem] h-[9rem] flex justify-center items-center`}
                    onClick={() => {
                      if (orgWebUrl) {
                        window.open(orgWebUrl, "_blank");
                      }
                    }}
                  >
                    {orgLogo ? (
                      <img
                        src={orgLogo}
                        alt="UnsaidTalksLogo"
                        className="w-fit h-fit px-2 py-2 object-cover"
                      />
                    ) : (
                      <div className="w-[10rem] h-[9rem] bg-[#F0F0F0] animate-pulse rounded-2xl"></div>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <div className="flex ">
                      <div
                        className={`${
                          orgWebUrl ? "hover:cursor-pointer" : "cursor-default"
                        }`}
                        onClick={() => {
                          if (orgWebUrl) {
                            window.open(orgWebUrl, "_blank");
                          }
                        }}
                      >
                        {orgName ? (
                          <div className="font-medium text-4xl text-[#1D2026]">
                            {orgName}
                          </div>
                        ) : (
                          <div className="w-[15rem] h-[1.5rem] bg-[#F0F0F0] animate-pulse"></div>
                        )}
                      </div>
                      <div className="ml-[0.75rem]">
                        <img src={TopRated} alt="TopRated" />
                      </div>
                    </div>
                    <div className="text-[#6E7485] mt-[0.625rem] cursor-default">
                      {orgDescription ? (
                        orgDescription
                      ) : (
                        <div className="w-[23.1rem] h-[1.5rem] bg-[#F0F0F0] animate-pulse"></div>
                      )}
                    </div>
                    <div className="flex gap-5 mt-[1.5rem]">
                      <div className="flex gap-1">
                        <div>
                          <img src={UserImage} alt="UserImage" />
                        </div>
                        <div className="text-[#6E7485] cursor-default">
                          {orgStudents ? (
                            orgStudents
                          ) : (
                            <div className="w-[5rem] h-[1.5rem] bg-[#F0F0F0] animate-pulse"></div>
                          )}
                        </div>
                      </div>
                      <div className="flex gap-1">
                        <div>
                          <img src={PlayImage} alt="PlayImage" />
                        </div>
                        {orgCourses ? (
                          <div className="text-[#6E7485] cursor-default">
                            {orgCourses} certificates
                          </div>
                        ) : (
                          <div className="w-[13.4rem] h-[1.5rem] bg-[#F0F0F0] animate-pulse"></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col ml-[0rem] md:ml-[8.8rem] lg:ml-[0rem] mt-[3rem] md:mt-[0rem]">
                  <div className="flex gap-2 justify-start lg:justify-end">
                    <div className="mb-[1.125rem]">
                      <img src={GlobeImage} alt="GlobeImage" />
                    </div>
                    {orgWebUrl ? (
                      <div
                        className="text-[#564FFD] hover:cursor-pointer"
                        onClick={() => {
                          window.open(orgWebUrl, "_blank");
                        }}
                      >
                        {orgWebUrl}
                      </div>
                    ) : (
                      <div className="w-[15rem] h-[1.5rem] bg-[#F0F0F0] animate-pulse"></div>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-3 justify-end">
                    {orgWhatsAppUrl && (
                      <div
                        onClick={() => window.open(orgWhatsAppUrl, "_blank")}
                      >
                        <img
                          src={WhatsAppLogo}
                          alt="WhatsApp Logo"
                          className="hover:cursor-pointer"
                        />
                      </div>
                    )}
                    {orgYoutubeUrl && (
                      <div onClick={() => window.open(orgYoutubeUrl, "_blank")}>
                        <img
                          src={YoutubeLogo}
                          alt="YouTube Logo"
                          className="hover:cursor-pointer"
                        />
                      </div>
                    )}
                    {orgFacebookUrl && (
                      <div
                        onClick={() => window.open(orgFacebookUrl, "_blank")}
                      >
                        <img
                          src={FacebookLogo}
                          alt="Facebook Logo"
                          className="hover:cursor-pointer"
                        />
                      </div>
                    )}
                    {orgInstagramUrl && (
                      <div
                        onClick={() => window.open(orgInstagramUrl, "_blank")}
                      >
                        <img
                          src={InstagramLogo}
                          alt="Instagram Logo"
                          className="hover:cursor-pointer"
                        />
                      </div>
                    )}
                    {orgTwitterUrl && (
                      <div onClick={() => window.open(orgTwitterUrl, "_blank")}>
                        <img
                          src={TwitterLogo}
                          alt="Twitter Logo"
                          className="hover:cursor-pointer"
                        />
                      </div>
                    )}
                    {orgLinkedinUrl && (
                      <div
                        onClick={() => window.open(orgLinkedinUrl, "_blank")}
                      >
                        <img
                          src={LinkedInLogo}
                          alt="LinkedIn Logo"
                          className="hover:cursor-pointer"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                ref={containerRef}
                className="flex flex-col xl:flex-row w-full gap-4 mt-[0.65rem]"
              >
                <div className="w-[100%] xl:w-[25%] ">
                  <div className="flex flex-col border p-4 cursor-default">
                    <div className="flex gap-1.5 mb-[1rem]">
                      {orgName ? (
                        <>
                          <div className="text-[#1D2026] text-lg  font-medium">
                            About
                          </div>
                          <div
                            className={`${
                              orgWebUrl
                                ? "hover:cursor-pointer"
                                : "cursor-default"
                            } text-[#1D2026] text-lg font-medium`}
                            onClick={() => {
                              if (orgWebUrl) {
                                window.open(orgWebUrl, "_blank");
                              }
                            }}
                          >
                            {orgName}
                          </div>
                        </>
                      ) : (
                        <div className="w-[13rem] h-[1.5rem] bg-[#F0F0F0] animate-pulse"></div>
                      )}
                    </div>
                    <div className="text-[#6E7485]">
                      {orgAbout ? (
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          rehypePlugins={[rehypeRaw]}
                        >
                          {orgAbout}
                        </ReactMarkdown>
                      ) : (
                        <>
                          <div className="w-full h-[1.5rem] bg-[#F0F0F0] mt-2 mb-2 animate-pulse"></div>
                          <div className="w-full h-[1.5rem] bg-[#F0F0F0] mb-2 animate-pulse"></div>
                          <div className="w-full h-[1.5rem] bg-[#F0F0F0] mb-2 animate-pulse"></div>
                          <div className="w-full h-[1.5rem] bg-[#F0F0F0] mb-2 animate-pulse"></div>
                          <div className="w-full h-[1.5rem] bg-[#F0F0F0] mb-2 animate-pulse"></div>
                          <div className="w-full h-[1.5rem] bg-[#F0F0F0] mb-2 animate-pulse"></div>
                          <div className="w-full h-[1.5rem] bg-[#F0F0F0] mb-2 animate-pulse"></div>
                          <div className="w-full h-[1.5rem] bg-[#F0F0F0] mb-2 animate-pulse"></div>
                          <div className="w-full h-[1.5rem] bg-[#F0F0F0] mb-2 animate-pulse"></div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-[100%] xl:w-[75%]">
                  <div className="flex flex-col">
                    <div className="flex flex-col items-start w-full pb-[2rem]">
                      <div className="text-xl font-semibold pl-[2.3rem] py-2.5 cursor-default">
                        Certificates
                      </div>
                      <div className="w-[12.1rem] border-b-4 border-[#FF6636] "></div>
                      <div className="w-full border-b-2 border-[#E9EAF0] "></div>
                    </div>
                    <div className="">
                      {contextList.length !== 0 &&
                        contextList.map(({ org_name, credentials }) => (
                          <div
                            className="flex flex-col gap-3 pb-6 last:pb-0"
                            key={org_name}
                          >
                            <div className="flex items-center gap-6 flex-wrap w-full">
                              {Array.isArray(credentials) &&
                              credentials.length > 0
                                ? credentials.map(
                                    (
                                      {
                                        certificate_name,
                                        certificate_description,
                                        certificate_template_id,
                                        certificate_tags,
                                        certificate_thumbnail_svg_url,
                                        certificate_redirect_url,
                                        certificate_issuance_count,
                                      },
                                      index
                                    ) => (
                                      <OrganizationProfileBanner
                                        key={index}
                                        certificate_name={certificate_name}
                                        certificate_description={
                                          certificate_description
                                        }
                                        certificate_template_id={
                                          certificate_template_id
                                        }
                                        certificate_tags={certificate_tags}
                                        certificate_thumbnail_svg_url={
                                          certificate_thumbnail_svg_url
                                        }
                                        certificate_redirect_url={
                                          certificate_redirect_url
                                        }
                                        certificate_issuance_count={
                                          certificate_issuance_count
                                        }
                                      />
                                    )
                                  )
                                : !hasFetchedCredentials && (
                                    <div className="flex flex-col gap-2 justify-center items-center mt-[8.5rem] w-full">
                                      <div className="text-[#1D2026] text-4xl font-semibold">
                                        No courses available
                                      </div>
                                      <div>
                                        {orgName} is yet to publish courses.
                                      </div>
                                    </div>
                                  )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {isLodingCredTemplates && <OrganizationProfileBanner />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrganizationPage;
