import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EntityConstants from "../../configs/EntityConstants";
import { GET } from "../../service/CRUDService";
import toast from "react-hot-toast";
import CfLoader from "../../common/CfLoader/CfLoader";
import IssuedCredentialLongCard from "../../components/Cards/IssuedCredentialLongCard/IssuedCredentialLongCard";

import Dropdown from "../../common/Dropdown/Dropdown";
import AuthContext from "../../store/auth-context";

const AllTransactions = () => {
  const authContext = useContext(AuthContext);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(6);
  const [startDay, setStartDay] = useState(0);
  const [endDay, setEndDay] = useState(7);
  const [status, setStatus] = useState("");
  const [search, setSearch] = useState("");
  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);
  const [respContentList, setRespContentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const navigate = useNavigate();
  const updateRange = (value) => {
    console.log("kkk", value);
    if (typeof value === "string") {
      const [start, end] = value.split("-");
      setStartDay(Number(start));
      setEndDay(Number(end));
    } else {
      console.error("Invalid value:", value);
    }
  };

  // Sample options array with name and value properties
  const options1 = [
    { name: "Recent", value: "0-7" },
    { name: "Last 30 Days", value: "0-30" },
    { name: "Last 3 Months", value: "0-90" },
    { name: "Last 6 Months", value: "0-180" },
    { name: "Beyond 6 Months", value: "0-365" },
  ];

  const updateStatus = (value) => {
    setStatus(value);
  };

  const options2 = [
    { name: "All", value: "" },
    { name: "Issued", value: "issued" },
    { name: "In Progress", value: "in-progress" },
    { name: "Revoked", value: "revoked" },
    { name: "Failed", value: "failed" },
    { name: "Partially Issued", value: "partially-issued" },
  ];

  const GET_ALL_TRANSACTIONS_LIST = (
    limit,
    offset,
    startDay,
    endDay,
    status
  ) => {
    setIsSearching(true);
    const temp_array = respContentList;
    setRespContentList([]);
    GET(
      `${EntityConstants.GET_TRANSACTIONS_DETAILS}?limit=${limit}&offset=${offset}&start_day=${startDay}&end_day=${endDay}&status=${status}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          toast.error(data.error);
          setRespContentList(temp_array);
        } else {
          setCount(data.count);
          authContext.IssuedOffsetHandler(offset);
          setRespContentList(data.results);
          setPrevious(data.previous);
          setNext(data.next);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSearching(false);
        setIsSearched(true);
      });
  };

  const SEARCH = (limit, offset, startDay, endDay, status, search) => {
    setIsSearching(true);
    GET(
      `${EntityConstants.GET_TRANSACTIONS_DETAILS}?limit=${limit}&offset=${offset}&start_day=${startDay}&end_day=${endDay}&status=${status}&search=${search}`
    )
      .then((response) => response.json())
      .then((data) => {
        setRespContentList(data.results);
        setCount(data.count);
        setPrevious(data.previous);
        setNext(data.next);
        setIsSearched(true);
        setIsSearching(false);
      })
      .catch((error) => {
        console.error("Error fetching search results:", error);
        setIsSearching(false);
        setIsSearched(false);
      });
  };

  useEffect(() => {
    if (search) {
      SEARCH(limit, authContext.issuedOffset, startDay, endDay, status, search);
    } else {
      GET_ALL_TRANSACTIONS_LIST(
        limit,
        authContext.issuedOffset,
        startDay,
        endDay,
        status
      );
    }
  }, [search, startDay, endDay, status, limit, authContext.issuedOffset]);

  useEffect(() => {
    if (authContext.issuedOffset !== null) {
      localStorage.setItem("transactionOffset", authContext.issuedOffset);
    }
  }, [localStorage.setItem("transactionOffset", authContext.issuedOffset)]);

  useEffect(() => {
    console.log(
      'localStorage.setItem("transactionOffset >>")',
      localStorage.getItem("transactionOffset")
    );
  }, [localStorage.getItem("transactionOffset")]);

  return (
    <div className="flex flex-col items-center w-full pb-3 pt-20 bg-cfSecondary min-h-screen ">
      <div className="w-full flex flex-col">
        <div
          className={`w-full bg-cfSecondary flex justify-center py-[0.8rem] px-8`}
        >
          <div
            className={`flex flex-col lg-xl:flex-row items-start lg-xl:items-center justify-between w-full`}
          >
            <span className="flex items-center gap-2">
              <Dropdown
                options={options2}
                selected={
                  options2.find((option) => option.value === status) ||
                  options2[0]
                }
                onSelect={updateStatus}
                buttonClass="border"
                menuClass="z-20"
              />
              <Dropdown
                options={options1}
                selected={
                  options1.find(
                    (option) => option.value === `${startDay}-${endDay}`
                  ) || options1[0]
                }
                onSelect={updateRange}
                buttonClass="border"
                menuClass="z-20"
              />
            </span>
            {/* Search */}
            <form
              onSubmit={(e) => e.preventDefault()}
              className="bg-cfSecondary p-2 rounded-lg w-full lg-xl:w-[25rem] self-center  md:self-end md:m-0 border xxs:mt-2"
            >
              <input
                type="text"
                name="search"
                id="search"
                onChange={(e) => {
                  authContext.IssuedOffsetHandler(0); //! This will reset the pagination when search query changes
                  setSearch(e.target.value.toLowerCase());
                }}
                placeholder="Search for certificate ID, transaction ID"
                className="outline-none border-none p-0 placeholder:text-sm w-full"
                style={{ fontSize: "0.875rem", lineHeight: "1.25rem" }}
              />
            </form>
          </div>
        </div>
        <div className="overflow-x-auto">
          <div className="flex flex-col justify-center gap-2 min-w-[73.5rem] md:w-full px-8">
            {isSearching ? (
              <CfLoader />
            ) : isSearched && respContentList.length === 0 ? (
              <div className="flex justify-center items-center w-full py-3 bg-cfSecondary text-gray-600 font-semibold text-lg">
                No results found
              </div>
            ) : (
              respContentList.map(
                ({
                  transaction_id,
                  issuer_credential_template_id,
                  credential_template_id,
                  certificate_name,
                  transaction_status,
                  preview_image,
                  completed_at,
                  updated_at,
                  organisation,
                  issued_by,
                  receivers,
                  credential_id,
                  userProfileImg,
                  issuance_type,
                }) => (
                  <IssuedCredentialLongCard
                    transaction_id={transaction_id}
                    name={certificate_name}
                    credential_template_id={credential_template_id}
                    issuer_credential_template_id={
                      issuer_credential_template_id
                    }
                    transaction_status={transaction_status}
                    completed_at={completed_at}
                    lastEdited={updated_at}
                    thumbnail_image={preview_image}
                    issued_by={issued_by}
                    userProfileImg={userProfileImg}
                    receivers={receivers}
                    issuance_type={issuance_type}
                  />
                )
              )
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between mt-6 px-8 w-full">
        <button
          onClick={() =>
            authContext.IssuedOffsetHandler(authContext.issuedOffset - limit)
          }
          className="flex items-center justify-center px-5 py-2 w-36 text-sm active:text-gray-700 capitalize transition-colors duration-200 bg-cfSecondary border rounded-md gap-x-2 hover:bg-gray-100 disabled:text-gray-400"
          disabled={previous === null}
        >
          <span className="flex items-center justify-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 rtl:-scale-x-100"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
              />
            </svg>

            <span>previous</span>
          </span>
        </button>

        <button
          onClick={() =>
            authContext.IssuedOffsetHandler(authContext.issuedOffset + limit)
          }
          className="flex items-center justify-center px-5 py-2 w-36 text-sm active:text-gray-700 capitalize transition-colors duration-200 bg-cfSecondary border rounded-md gap-x-2 hover:bg-gray-100 disabled:text-gray-400"
          disabled={next === null}
        >
          <span className="flex items-center justify-center gap-2">
            <span>Next</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 rtl:-scale-x-100"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
};

export default AllTransactions;
