import React, { useEffect, useState } from 'react'
const ToggleButton = ({First_Text,Second_Text,showText,LeftIcon,RightIcon,onToggle,UploadMethodBool}) => {

    const [isChecked, setIsChecked] = useState(UploadMethodBool);
    const handleCheckboxChange = (bool) => {
        setIsChecked(bool)
    }
    useEffect(()=>{
        onToggle(isChecked)
    },[isChecked]);
    useEffect(()=>{
      setIsChecked(UploadMethodBool)
    },[UploadMethodBool])
  return (
      <label className='flex cursor-pointer select-none items-center justify-start m-0'>
        <div className='flex w-full items-center bg-cfSecondary py-2'>
        <div
            className={`flex gap-2 w-[calc(100%+1rem)] pb-2.5 items-center justify-center hover:text-cfPrimary  border-b  ${
              !isChecked ? 'border-b-cfPrimaryDark text-cfPrimaryDark shadow-[inset_0_-4px_5px_-5px] shadow-cfPrimaryLight' : 'text-body-color'
            }`}
            title={First_Text}        
            onClick={()=>handleCheckboxChange(false)}
          >
           {LeftIcon && <LeftIcon size={25}/>}
           <p>{First_Text}</p>
          </div>
          <div
            className={`flex gap-2 w-[calc(100%+1rem)] pb-2.5 items-center justify-center hover:text-cfPrimary border-b ${
              isChecked ? 'border-b-cfPrimaryDark text-cfPrimaryDark shadow-[inset_0_-4px_5px_-5px] shadow-cfPrimaryLight' : 'text-body-color'
            }`}
            title={Second_Text}
            onClick={()=>handleCheckboxChange(true)}
          >
            {RightIcon && <RightIcon size={25}/>}
            <p>{Second_Text}</p>
          </div>
        </div>
      </label>
  )
}

export default ToggleButton
