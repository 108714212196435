// Images
import Lang from "../../images/Language Translate2.0.png";
import Favicon from "../../images/Favicon.svg";
// React
import { useState, useEffect, useContext } from "react";
import { Disclosure, Menu } from "@headlessui/react";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
//Services
import { GET, LOGOUT } from "../../service/CRUDService";
// Constants
import EntityConstants from "../../configs/EntityConstants";
// Store
import AuthContext from "../../store/auth-context";
// React Icons
import { BiLogOutCircle, BiSolidUserCircle } from "react-icons/bi";

import toast from "react-hot-toast";

import { useTranslation } from "react-i18next";

import AppConstants from "../../configs/AppConstants";

import { googleLogout } from "@react-oauth/google";

import { useGoogleOneTapLogin } from "@react-oauth/google";

import { GLogin } from "../../service/OAuthLogin";

import ProfileIcon from "../../resources/icons/profile-circle.svg";

import NotificationIcon from "../../resources/icons/notification.svg";

import HelpIcon from "../../resources/icons/message-question.svg";

import { MLogin } from "../../service/OAuthLogin";

//main NavBar component
export default function Navbar({ TriggerLoginModuleBtn, chooseSignUp_SignIn }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const home = "/";
  const [code, setCode] = useState("");

  useGoogleOneTapLogin({
    disabled: authContext.isLoggedIn,
    onSuccess: async (credentialResponse) => {
      const login = await GLogin(credentialResponse, "success");
      if (login === true) {
        authContext.login() && authContext.googlelogin();
      }
    },
    onError: (error) => GLogin(error, "failed"),
    googleAccountConfigs: {
      client_id: AppConstants.CLIENT_ID,
    },
  });
  const languages = [
    { value: "en", label: `${t("navbar.lang.en")}` },
    { value: "es", label: `${t("navbar.lang.es")}` },
    { value: "swa", label: `${t("navbar.lang.swa")}` },
  ];

  const defaultLanguageValue = i18n.language; // Assuming i18n.language is a string representing the language value

  // Find the language object that matches the defaultLanguageValue
  const defaultLanguageObject = languages.find(
    (language) => language.value === defaultLanguageValue
  );

  // Set the default language object in the useState
  const [language, setLanguage] = useState(
    defaultLanguageObject || languages[0]
  );

  const logout = async () => {
    try {
      authContext.isGoogleLogin && googleLogout(); // ONLY DO IT IF GOOGLE LOGIN IS USED //! NOW DOING IT ALL TYPES OF LOGIN
      const response = await LOGOUT(EntityConstants.LOGOUT);

      if (response.ok) {
        authContext.createUserProfileDetails({});
        localStorage.clear();
        authContext.logout();
        authContext.createUserProfile(null);
        authContext.createTenantName(null);
        authContext.createTenantId(null);
        location.pathname.startsWith("/issuer") && navigate("/");
        location.pathname === "/" && window.location.reload();
        if (location.pathname === "/discovery") {
          navigate("/");
          window.location.reload();
        }
      }
    } catch (error) {
      // Handle errors here, you can use console.error or any logging mechanism
      toast.error("Logout failed:", error);
    }
  };

  const handleCurrentUser = async (loginType = null) => {
    try {
      const response = await GET(EntityConstants.CURRENT_USER);

      if (response.ok) {
        const data = await response.json();
        if (data !== authContext.userDetails) {
          //use and complete logic here
          authContext.login();
          if (loginType === "password") {
            authContext.createUserProfileDetails(data);
          } else if (loginType === "google") {
            authContext.createUserProfileDetails({
              first_name: data.given_name,
              last_name: data.family_name,
              email: data.email,
              profile_photo: data.image_url,
            });
          }

          authContext.createUserProfile(data.user_profile_id);
          authContext.createTenantName(data.tenant_name);
          authContext.createTenantId(data.tenant_id);
        } else {
          const errorData = await response.json();
          toast.error(
            errorData.message || "Failed to get User Details\nPlease Retry..."
          );
        }
      }
    } catch (error) {
      // Handle unexpected errors
      console.error("Failed to fetch current user:", error);
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  const handleMicrosoftRedirect = async () => {
    try {
      const codeVerifier = sessionStorage.getItem("pkce_code_verifier");

      const response = await fetch(EntityConstants.MICROSOFT_AUTH_CODE, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          client_id: AppConstants.MICROSOFT_CLIENT_ID,
          scope: AppConstants.MICROSOFT_SCOPE,
          code: code,
          redirect_uri: AppConstants.HTTPS_HOST,
          grant_type: AppConstants.MICROSOFT_GRANT_TYPE,
          code_verifier: codeVerifier,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const loginStatus = await MLogin(data.access_token);
        if (loginStatus) {
          authContext.login() && authContext.microsoftlogin();
        }
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch access token: ", errorData);
      }
    } catch (error) {
      console.error("Error during token exchange: ", error);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setCode(urlParams.get("code"));
  }, []);

  useEffect(() => {
    code && handleMicrosoftRedirect();
  }, [code]);

  const path = location.pathname;

  useEffect(() => {
    const path = location.pathname;

    // Check if the path is not equal to "/" and doesn't start with "/discover/"
    if (!path.startsWith("discover/")) {
      // Handle the redirect or any other action you want to perform
      //if check for auth_token if exists then call handleCurrentUser().
      localStorage.getItem("auth_token") && handleCurrentUser("password");
    }
  }, [authContext.isLoggedIn, location.pathname]);

  useEffect(() => {
    if (authContext.isGoogleLogin) {
      localStorage.getItem("auth_token") && handleCurrentUser("google");
    }
  }, [authContext.isGoogleLogin]);

  useEffect(() => {
    i18n.changeLanguage(language.value);
  }, [language]);

  return (
    <div className="sticky top-0">
      <Disclosure as="nav" className="bg-cfSecondary border-b-[1.5px]">
        {({ open }) => (
          <>
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex h-[5rem] items-center justify-between">
                <NavLink
                  to={home}
                  className={
                    "flex gap-4 items-center hover:text-inherit hover:no-underline"
                  }
                >
                  <img className="w-64" src={Favicon} alt="Credflow" />
                </NavLink>
                <div className="flex items-center gap-5">
                  {authContext.isLoggedIn && (
                    <>
                      <p>
                        <img
                          src={NotificationIcon}
                          alt=""
                          className="min-w-[1.50rem]  w-7 cursor-pointer hidden"
                        />
                      </p>
                      <p>
                        <img
                          src={HelpIcon}
                          alt=""
                          className="min-w-[1.50rem] w-7 cursor-pointer hidden"
                        />
                      </p>
                    </>
                  )}
                  <div className="flex items-center">
                    {authContext.isLoggedIn &&
                    Object.values(authContext.userDetails).length !== 0 ? (
                      <Menu as="div" className="relative ">
                        <div>
                          <Menu.Button className="flex max-w-xs items-center text-md text-cfNavBarText focus:outline-none pr-4">
                            {authContext.userDetails.profile_photo ? (
                              <span className="flex items-center gap-1">
                                <img
                                  className="h-8 w-8 rounded-full"
                                  src={authContext.userDetails.profile_photo}
                                  alt=""
                                />
                                <p>{authContext.userDetails?.first_name}</p>
                                <p>{authContext.userDetails?.last_name}</p>
                              </span>
                            ) : (
                              <span className="flex items-center gap-1">
                                <img
                                  src={ProfileIcon}
                                  alt=""
                                  srcSet=""
                                  className="text-gray-400 w-7"
                                />
                                <p>{authContext.userDetails?.first_name}</p>
                                <p>{authContext.userDetails?.last_name}</p>
                              </span>
                              // <BiSolidUserCircle size={35} className="text-gray-400"/>
                            )}
                          </Menu.Button>
                        </div>
                        <Menu.Items className="flex flex-col text-gray-500 absolute right-0 z-10 mt-2 pt-3 px-2 text-md w-max origin-top-right rounded-md bg-cfSecondary py-1 shadow-lg ring-1 ring-cfSecondary ring-opacity-5 focus:outline-none">
                          <div className="">
                            <hr />
                            <Menu.Item className="px-2 pb-1 py-2">
                              <NavLink
                                to={path === "/" ? "issuer/profile" : "profile"}
                                className="hover:no-underline hover:text-gray-600 text-cfNavBarText flex items-center gap-1"
                              >
                                <div className="w-4 h-4 rounded-full flex items-center justify-center">
                                  {
                                    <img
                                      src={
                                        authContext.userDetails.profile_photo || ProfileIcon
                                      }
                                      alt=""
                                      srcset=""
                                      className="w-4 h-4 rounded-full"
                                    />
                                  }
                                </div>
                                &nbsp;
                                {authContext.userDetails?.first_name}&nbsp;
                                {authContext.userDetails?.last_name}
                              </NavLink>
                            </Menu.Item>
                            <hr />
                            <Menu.Item className="px-2 py-1">
                              <button
                                type="button"
                                className="flex items-center gap-1 hover:text-gray-600 text-cfNavBarText bg-cfSecondary focus:outline-none"
                                onClick={logout}
                              >
                                <BiLogOutCircle />
                                <p className="ml-1">Sign out</p>
                              </button>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Menu>
                    ) : (
                      <div className="flex items-center gap-3 justify-between">
                        <div>
                          <button
                            className="flex flex-col max-w-xs items-end font-CfPrimaryFont text-sm sm:text-base text-cfSignUpText leading-0 sm:leading-6 font-medium focus:outline-none"
                            onClick={() => {
                              chooseSignUp_SignIn(false);
                              TriggerLoginModuleBtn();
                            }}
                          >
                            <p>Sign up to try your first template for free!</p>
                          </button>
                        </div>
                        <div>
                          <button
                            className="flex max-w-xs items-center justify-center text-lg font-semibold text-cfSecondary focus:outline-none bg-cfPrimaryButton py-2.5 px-4 rounded-xl"
                            onClick={() => {
                              chooseSignUp_SignIn(true);
                              TriggerLoginModuleBtn();
                            }}
                          >
                            <p className="text-base">Sign In</p>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
    </div>
  );
}
