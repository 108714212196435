// React
import { useContext, useState, useEffect } from "react";
import { BsPersonCircle, BsLockFill } from "react-icons/bs";
import { BiShow, BiHide } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
// Service
import { POST } from "../../service/CRUDService";
// Utils
import { state, schema } from "./SignInSideCurtains.utils";
// Third Party
import { Form, Formik } from "formik";
import toast from "react-hot-toast";
// Constants
import EntityConstants from "../../configs/EntityConstants";
// Store & Context
import AuthContext from "../../store/auth-context";

import { useTranslation } from "react-i18next";

import { useLocation } from "react-router-dom";

import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { GLogin } from "../../service/OAuthLogin";

import GoogleIcon from "../../resources/icons/Google-Social-icon.svg";
import MicrosoftIcon from "../../resources/icons/microsoft.svg";
import { microsoftAuth } from "../../service/OAuthLogin";
import ReCAPTCHA from "react-google-recaptcha";
import AppConstants from "../../configs/AppConstants";

const SignInSideCurtains = ({ toggleComponent, closeModal }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const [hideShowpassword, setHideShowpassword] = useState("password");
  const [captchaValue, setCaptchaValue] = useState(null);

  //Local CSS vars:

  const InputBorderRadius = "0.5rem";
  const InputPaddingX = "0.875rem";
  const InputPaddingY = "0.625rem";
  const InputLabelPaddingB = "0.375rem";
  const CheckboxFocusRadius = "0.3rem";
  const InputContainerHeight = "4.375rem";
  const RememberForgerText = "0.875rem";

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async (values) => {
    try {
      if (!captchaValue) {
        toast.error("Please complete the CAPTCHA.");
        return;
      }
      const response = await POST(EntityConstants.LOGIN, {
        ...values,
        captchaToken: captchaValue,
      });

      const data = await response.json();

      if (response.ok) {
        toast.success(`Logged In Successfully`);
        // localStorage.setItem("auth_token", data.token);
        localStorage.setItem("auth_token", data.access);
        localStorage.setItem("refresh_token", data.refresh);

        authContext.login();

        location.pathname === "/" && navigate("/issuer/discover");
        // location.pathname.startsWith("/credentials") && navigate(`/issuer${location.pathname}`);
        closeModal();
        return;
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      // Handle unexpected errors
      console.error("Login failed:", error);
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  const login = useGoogleLogin({
    onSuccess: async (credentialResponse) => {
      const login = await GLogin(credentialResponse, "success");
      if (login === true) {
        // console.log(login, "login status");
        authContext.login() && authContext.googlelogin();
        closeModal();
      }
    },
    onError: (error) => {
      GLogin(error, "failed");
    },
  });

  return (
    <Formik
      enableReinitialize
      initialValues={state}
      validationSchema={schema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, touched, errors, handleChange, handleSubmit }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <h3 className="text-4xl font-medium leading-6 text-cfSignInSignUpHeadingText px-8">
            Sign in
          </h3>
          <div className="flex flex-col justify-center items-center text-left w-full h-full overflow-hidden font-CfPrimaryFont px-8 py-6">
            <div className="py-3">
              <p className="text-cfSignInSignUpIntroMessageText text-base">
                Sign in with your company or personal account.
              </p>
            </div>
            <div
              id="inputSignUp"
              className={`flex flex-col gap-[0.625rem] w-full`}
            >
              <div
                className={`flex flex-col gap-4 rounded-md bg-inherit w-full h-[${InputContainerHeight}]`}
              >
                <label
                  htmlFor="email"
                  className="my-auto font-medium text-sm text-cfSignInSignUpInputLabelText after:content-['*'] after:text-red-500"
                >
                  Email&nbsp;
                </label>

                <input
                  type="text"
                  name="email"
                  id="email"
                  required
                  placeholder={"Enter your email"}
                  autoComplete="true"
                  className="rounded-md w-full h-12 focus:outline-none border border-cfSignInSignUpInputBorder text-base text-cfSignInSignUpInputPlaceholderText"
                  style={{ borderRadius: InputBorderRadius }}
                  value={values.email.trim()}
                  onChange={handleChange}
                />
              </div>
              <p className="text-red-600">
                {errors.email && touched.email && `${errors.email}`}
              </p>
              <div
                className={`flex flex-col gap-4 rounded-md bg-inherit w-full h-[${InputContainerHeight}]`}
              >
                <label
                  htmlFor="password"
                  className="my-auto font-medium text-sm text-cfSignInSignUpInputLabelText after:content-['*'] after:text-red-500"
                >
                  Password&nbsp;
                </label>
                <div className="flex items-center justify-between rounded-md w-full h-11 mr-[.14rem] pl-2 border border-cfSignInSignUpInputBorder">
                  <input
                    type={hideShowpassword}
                    name="password"
                    id="password"
                    required
                    placeholder={`password`}
                    autoComplete="true"
                    className="focus:outline-none text-base text-cfSignInSignUpInputPlaceholderText border-none w-full"
                    value={values.password.trim()}
                    style={{ borderRadius: InputBorderRadius }}
                    onChange={handleChange}
                  />
                  <div className="pl-1 pr-2 text-cfSignInSignUpInputPlaceholderText">
                    {hideShowpassword === "password" && (
                      <BiShow
                        onClick={() => setHideShowpassword("text")}
                        className="cursor-pointer"
                      />
                    )}
                    {hideShowpassword === "text" && (
                      <BiHide
                        onClick={() => setHideShowpassword("password")}
                        className="cursor-pointer"
                      />
                    )}
                  </div>
                </div>
              </div>
              <p className="text-red-600">
                {errors.password && touched.password && `${errors.password}`}
              </p>
            </div>
            <div className="flex text-base justify-between items-center w-full mt-3">
              <div>
                <input
                  type="checkbox"
                  name="remember"
                  id="remember"
                  className="focus:ring-transparent focus:outline-none peer mx-1 mb-0.5 border border-cfSignInSignUpInputBorder"
                  style={{ borderRadius: CheckboxFocusRadius }}
                />
                <label
                  htmlFor="remember"
                  className={`mb-0 ml-1 text-sm font-medium`}
                >
                  Remember for 30 days
                </label>
              </div>
              <div>
                <a
                  href="#forgot_password"
                  className={`text-cfForgotPasswordText hover:text-cfPrimaryDark text-sm font-medium focus:outline-none`}
                >
                  Forgot password
                </a>
              </div>
            </div>
            <div className="w-full mt-6 h-44  flex flex-col justify-between">
              <div className="flex flex-col gap-2 items-center">
                <input
                  type="submit"
                  value={"Sign in"}
                  className="rounded-xl text-base font-semibold bg-cfButton hover:bg-cfPrimaryDark py-1 px-5 text-cfSecondary w-full h-11"
                />
                <div className="flex custom-recaptcha-width justify-center items-center">
                  <ReCAPTCHA
                    sitekey={AppConstants.GOOGLE_CAPTCHA_SITE_KEY}
                    onChange={handleCaptchaChange}
                  />
                </div>
              </div>
              <div className="flex items-center w-full gap-2.5 text-cfSignInSignUpOrText">
                <hr className="w-1/2" />
                <p>or</p>
                <hr className="w-1/2" />
              </div>
              <div className="flex mt-[0.5rem] gap-4">
                <button
                  type="button"
                  onClick={login}
                  className="flex items-center gap-2 justify-center w-full h-11 border rounded-full"
                >
                  <img src={GoogleIcon} alt="google" />
                  <p className="font-semibold text-base font-CfPrimaryFont ">
                    Sign in
                  </p>
                </button>
                <button
                  type="button"
                  onClick={microsoftAuth}
                  className="flex items-center gap-2 justify-center w-full h-11 border rounded-full"
                >
                  <img src={MicrosoftIcon} alt="google" />
                  <p className="font-semibold text-base font-CfPrimaryFont ">
                    Sign in
                  </p>
                </button>
              </div>
            </div>
            <div className="flex text-[1rem] leading-[1.2rem] px-0.5 pb-2 text-sm mt-5 mb-3">
              <p className="text-cfDontHaveAccountText">
                Don’t have an account?
              </p>
              &nbsp;
              <button onClick={toggleComponent} className="flex">
                <p className="hover:text-cfPrimaryDark font-semibold text-cfDontHaveAccountSignUpText">
                  Sign up
                </p>
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignInSideCurtains;
